import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GService } from 'src/app/services/g.service';

@Component({
  selector: 'app-cat',
  templateUrl: './cat.page.html',
  styleUrls: ['./cat.page.scss'],
})


export class CatPage implements OnInit {

  constructor(private g: GService, private router: Router) { }

  ngOnInit() {}
}
