import { Component, Input, OnInit } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { FirebaseService } from 'src/app/services/firebase.service';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';


@Component({
  selector: 'app-youtubemodal',
  templateUrl: './youtubemodal.page.html',
  styleUrls: ['./youtubemodal.page.scss'],
})
export class YoutubemodalPage implements OnInit {

  @Input() videoURL: string;

  video: SafeResourceUrl;
  videoCatch = false;
  videoError = false;
  constructor(private fire: FirebaseService, private modalCtrl: ModalController, private screenOrient: ScreenOrientation,
              private platform: Platform, private sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.platform.ready().then(async () => {
      // ######### DISABLE ANDROID HARDWARE BACKBUTTON
      this.platform.backButton.subscribeWithPriority(9999, () => {
        document.addEventListener('backbutton', (event) => {
          event.preventDefault();
          event.stopPropagation();
          console.log('BACK');
        }, false);
      });
      this.screenOrient.unlock();

      let videoArray = this.videoURL.split("/");
      this.video = this.sanitizer.bypassSecurityTrustResourceUrl("https://www.youtube.com/embed/" + videoArray[videoArray.length - 1] + "?rel=0&autoplay=1");
      this.videoCatch = true;
    });
  }

  dismiss() {
    this.screenOrient.lock(this.screenOrient.ORIENTATIONS.PORTRAIT);
    this.modalCtrl.dismiss();
  }

}
