import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { GService } from "src/app/services/g.service";
import { FirebaseService } from "src/app/services/firebase.service";
import { AlertController, ModalController, NavController } from "@ionic/angular";
import { HomepopoverPage } from "src/app/utility/homepopover/homepopover.page";
import { StorageService } from "src/app/services/storage.service";

@Component({
	selector: "app-welcome",
	templateUrl: "./welcome.page.html",
	styleUrls: ["./welcome.page.scss"],
})
export class WelcomePage implements OnInit {
	onInitSlide = true;
	logocat: string;
	sliders: any[] = [];

	@ViewChild("popupTemplate", { static: false }) popupTemplate: any;

	constructor(
		private router: Router,
		private g: GService,
		private fire: FirebaseService,
		private navCtrl: NavController,
		public modalController: ModalController,
		private activatedRoute: ActivatedRoute,
		private storage: StorageService
	) {}

	async ngOnInit() {
		await this.initSlide();
		if (this.g.appPrefs.webMode) this.initPopup();

		this.activatedRoute.queryParams.subscribe((params: any) => {
			if (params) {
				let lang = params.language;
				if (lang) {
					console.log("LANG", lang, lang == "en");
					if (lang == "en" || lang == "it") {
						this.g.localPrefs.language = lang;
						this.storage.setLocalPrefs(this.g.localPrefs);
					}
				}
			}
		});
	}

	goTo(loc: string) {
		this.navCtrl.navigateForward(["/" + loc]);
	}

	async initSlide() {
		this.onInitSlide = true;
		const slidersDocs = this.fire.getSettingsImagesData("gestione immagini");
		await this.fire.readImageArray(slidersDocs.home_slideshow).then(
			(resImgs: string[]) => {
				this.sliders = resImgs;
				this.onInitSlide = false;
			},
			(err) => {
				console.error("MAIN GET IMAGE ERROR: ", err);
			}
		);
		this.fire.readImage(slidersDocs.logo_catalogo.id).then(
			(resImg: string) => {
				this.logocat = resImg;
				this.onInitSlide = false;
			},
			(err) => {
				console.error("MAIN GET IMAGE ERROR: ", err);
			}
		);
	}

	async initPopup() {
		const modal = await this.modalController.create({
			component: HomepopoverPage,
			cssClass: "uiHomeDialog",
			backdropDismiss: false,
		});
		await modal.present();
		//  setTimeout(() => {
		//   modal.dismiss();
		// }, 5000);
	}
}
