import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { createAnimation, NavController } from '@ionic/angular';
import { FirebaseService } from 'src/app/services/firebase.service';
import { GService } from 'src/app/services/g.service';
import { SearchService } from 'src/app/services/search.service';
import Keyboard from "simple-keyboard";

@Component({
  selector: 'app-search',
  templateUrl: './search.page.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./search.page.scss', "../../../assets/css/keyboard.scss"],
})
export class SearchPage implements OnInit {
  searchString: string = '';

  prodsIndex: number;
  prods: any[] = [];
  prodsImg: { [id: string]: { img: string, available: boolean; } } = {};

  catIndex = '';
  classes: any[] = [];
  classesImg: { [id: string]: { img: string, available: boolean; } } = {};

  initSearch = false;
  initSearchString: string = '';
  keyboard: Keyboard;

  segment = "products";

  @ViewChild("searchBar", { static: false }) searchBar: any;

  constructor(private route: ActivatedRoute, private fire: FirebaseService, private g: GService, private navCtrl: NavController, private search: SearchService) { }

  ngOnInit() {
    this.route.params.subscribe((param) => {
      this.searchString = param.search;
      if (this.searchString === 'initsearch') {
        this.initSearch = false;
      } else {
        this.initSearch = true;
        this.searchProds();
        this.searchCat();
      }
    });
  }

  ionViewWillEnter() {
    if (this.searchString === 'initsearch' && !this.g.appPrefs.bMobile) {
      this.keyboard = new Keyboard("keyboard", {
        onChange: (input) => this.onChange(input),
        onKeyPress: (button) => this.onKeyPress(button),
      });
    } else if (this.searchString === 'initsearch' && this.g.appPrefs.bMobile) {
      setTimeout(() => {
        this.searchBar.setFocus();
      }, 500);
    }
  }


  // FILTER CATEGORIES
  onKeyPress = (button: string) => {
    if (button === "{shift}" || button === "{lock}") {
      this.handleShift();
    } else if (button === "{enter}") {
      /* handle enter button*/
      this.toSearch();
    }
  };

  handleShift = () => {
    const currentLayout = this.keyboard.options.layoutName;
    const shiftToggle = currentLayout === "default" ? "shift" : "default";
    this.keyboard.setOptions({
      layoutName: shiftToggle,
    });
  };

  onChange = (input: string) => {
    this.initSearchString = input;
  };

  searchProds() {
    if (this.prods.length <= 0) {
      this.prodsIndex = 0;
    }
    console.log('START AT: ', this.prodsIndex);
    this.fire.searchProds(this.searchString, this.prodsIndex).subscribe({
      next: (res: any) => {
        res.products.forEach(async p => {
          const prodData = this.fire.getProductDataByID(p);
          this.prods.push(prodData);
          await this.readProductsImg(prodData.immagine, prodData.codice, prodData);
          this.prodsImg[prodData.codice].available = true;
          const anim = createAnimation().addElement(document.querySelectorAll('.uiSwiperFadeProd' + prodData.codice)).duration(500).fromTo('opacity', '0', '1');
          anim.play();
        });
        this.prodsIndex = res.index;
      },
      error: (err: Error) => {
        console.error("Check fs folders: ERROR", err);
      }
    });
  }
  /*
  searchProds() {
    if (this.prods.length > 0) {
      this.prodsIndex = this.prods[this.prods.length - 1].nome[this.g.localPrefs.language];
    } else {
      this.prodsIndex = '';
    }
    this.fire.searchProds(this.searchString, this.prodsIndex).subscribe({
      next: (products) => {
        products = products.filter((doc) => {
          return doc.nome[this.g.localPrefs.language].toLowerCase().includes(this.searchString.toLowerCase());
        });
        products.forEach(async p => {
          this.prods.push(p);
          await this.readProductsImg(p.immagine, p.codice, p);
          this.prodsImg[p.codice].available = true;
          const anim = createAnimation().addElement(document.querySelectorAll('.uiSwiperFadeProd' + p.codice)).duration(500).fromTo('opacity', '0', '1');
          anim.play();
        });
      },
      error: (err: Error) => {
        console.error("Check fs folders: ERROR", err);
      }
    });
  }
  */

  async readProductsImg(prodImg, code, doc) {
    this.prodsImg[code] = { img: '', available: false };
    await this.fire.readImage(prodImg.id).then((resImg: string) => {
      this.prodsImg[code].img = resImg;
    }, (err) => {

    });

    await this.fire.readImageArray(doc.slides).then((resImgs: string[]) => {
      for (let res of resImgs) {
      }
    }, (err) => {

    });
  }

  onInfiniteScroll(event: any) {
    this.searchProds();
    event.target.complete();
  }

  searchCat() {
    this.fire.searchCats(this.searchString).subscribe({
      next: (categories) => {
        this.classes = categories;
        this.classes.forEach(async clas => {
          clas.categories.forEach(async c => {
            await this.readClassImg(c.cat.immagine, c.cat.id);
            this.classesImg[c.cat.id].available = true;
            if (c.families.length > 0) {
              c.families.forEach(async f => {
                await this.readClassImg(f.immagine, f.id);
                this.classesImg[f.id].available = true;
              });
            }
          });
        });
      },
      error: (err: Error) => {
        console.error("Search category: ERROR", err);
      }
    });
  }

  async readClassImg(classImg, id) {
    this.classesImg[id] = { img: "", available: false };
    await this.fire.readImage(classImg.id).then(
      (resImg: string) => {
        this.classesImg[id].img = resImg;
      },
      (err) => {

      }
    );
  }

  navigateProd(prod: string) {
    this.navCtrl.navigateForward("/info/" + prod);
  }

  navigateCat(clas: string, cat: string) {
    if (this.g.appPrefs.bMobile === false) {
      this.navCtrl
        .navigateForward(
          [
            "/cat",
            {
              outlets: {
                SideRouter: "side/" + clas + "/" + "-",
                MainRouter: "main/" + clas + "/" + cat + "/" + "-",
              },
            },
          ],
          { skipLocationChange: false, replaceUrl: true }
        )
        .then(() => {
          this.search.selectedItem = cat;
        });
    } else {
      this.navCtrl.navigateForward(["/cat", { outlets: { SideRouter: "main/" + clas + "/" + cat + "/" + "-" } }]);
    }
  }

  navigateFam(clas: string, cat: string, fam: string) {
    if (this.g.appPrefs.bMobile === false) {
      this.navCtrl.navigateForward(
        [
          "/cat",
          {
            outlets: {
              SideRouter: "side/" + clas + "/" + cat,
              MainRouter: "main/" + clas + "/" + cat + "/" + fam,
            },
          },
        ]
      ).then(() => {
        this.search.selectedItem = fam;
      });
    } else {
      this.navCtrl.navigateForward(["/cat", { outlets: { SideRouter: "main/" + clas + "/" + cat + "/" + fam } }]);
    }
  }

  back() {
    this.navCtrl.back();
  }

  toSearch() {
    this.navCtrl.navigateForward("/search/" + this.initSearchString);
  }

}
