import { Component } from "@angular/core";
import { Platform, ToastController, MenuController, PopoverController } from "@ionic/angular";
import { NavController } from "@ionic/angular";
import { GService } from "./services/g.service";
import { Device } from "@ionic-native/device/ngx";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { FCM } from "@ionic-native/fcm/ngx";
import { FirebaseService } from "./services/firebase.service";
import { Network } from "@ionic-native/network/ngx";
import { StorageService } from "./services/storage.service";
import { ScreenOrientation } from "@ionic-native/screen-orientation/ngx";
import { SearchService } from "./services/search.service";
import { DocumentData } from "angularfire2/firestore";
import { NavigationEnd, NavigationError, NavigationStart, Router, Event, ActivatedRoute } from "@angular/router";
import { InAppBrowser } from "@ionic-native/in-app-browser/ngx";
import * as env from "src/environments/env.json";
import { LanguagepopoverPage } from "./utility/languagepopover/languagepopover.page";
import { TranslatePipe } from "./pipes/translate.pipe";
import { InitService } from "./services/init.service";

declare let navigator: any;
declare let document: any;

@Component({ selector: "app-root", templateUrl: "app.component.html", styleUrls: ["app.component.scss"] })
export class AppComponent {
	rootPage: any = "WelcomePage";
	os: string;
	successToast: HTMLIonToastElement;
	errorToast: HTMLIonToastElement;
	noConnectionToast: HTMLIonToastElement;
	menuDoc: DocumentData[] = [];
	getData = false;
	prevLocation: string;
	actualLocation: string;
	appLoading = true;
	fullscreenBrowser = false;
	darkmode = false;

	env: any = (env as any).default;

	countries = this.env.countries;
	/*
	countries: { name: string; flag: string }[] = [
		{ name: "it", flag: "../assets/flags/1x1/gb.svg" },
		{ name: "en", flag: "../assets/flags/1x1/it.svg" },
	];
	*/
	constructor(
		private platform: Platform,
		private g: GService,
		private navCtrl: NavController,
		private iab: InAppBrowser,
		private device: Device,
		private splashScreen: SplashScreen,
		private fcm: FCM,
		public toastCtrl: ToastController,
		private fireService: FirebaseService,
		private network: Network,
		private storage: StorageService,
		private screenOrient: ScreenOrientation,
		private search: SearchService,
		public menuCtrl: MenuController,
		private router: Router,
		public popoverCtrl: PopoverController,
		private translate: TranslatePipe,
		private init: InitService,
		private activatedRoute: ActivatedRoute
	) {
		this.platform.ready().then(() => {
			this.init.initializeApp();
		});
		this.router.events.subscribe((event: Event) => {
			if (event instanceof NavigationEnd) {
				if (this.init.hiddenMenu) {
					this.init.hiddenMenu = false;
				}
				if (this.init.hiddenHome) {
					this.init.hiddenHome = false;
				}
				this.prevLocation = this.actualLocation;
				this.actualLocation = event.url;
				if (typeof this.actualLocation !== "undefined") {
					if (this.actualLocation === "/sync") {
						this.init.hiddenMenu = true;
						this.init.hiddenHome = true;
						return;
					} else if (this.actualLocation === "/welcome") {
						this.init.hiddenHome = true;
						return;
					} else if (this.actualLocation.includes("/web")) {
						this.g.prevLocation = this.prevLocation;
						this.search.selectedItem = "";
						this.search.cancelFilter();
						return;
					} else if (
						this.actualLocation.includes("/cat") ||
						this.actualLocation.includes("/prod") ||
						this.actualLocation.includes("/pref")
					) {
						this.search.selectedItem = "";
						this.search.cancelFilter();
						return;
					}
				}
			}
			if (event instanceof NavigationError) {
				// Hide loading indicator
				console.log("NAV ERROR");
			}
		});
	}

	/* LISTEN CONNECTION CHANGES */
	private listenConnectionChanges() {
		if (this.network.type === "none") {
			this.noConnectionAlert();
			this.g.appPrefs.bOffline = true;
		} else {
			this.g.appPrefs.bOffline = false;
		}
	}

	private async noConnectionAlert() {
		if (this.noConnectionToast) {
			this.noConnectionToast.dismiss();
		}
		this.noConnectionToast = await this.toastCtrl.create({
			message: "Connessione assente",
			duration: 2000,
			color: "warning",
			position: "bottom",
		});
		this.noConnectionToast.present();
	}

	/* INIT SETTINGS FROM LANGUAGE */
	initSettings() {
		this.g.localPrefs = this.storage.getLocalPrefs();
		console.log("PREFS", this.storage.getLocalPrefs());
		// #################### APPLICO DEFAULT
		this.g.localPrefs.language = "it";

		// console.log('CLIENT LANG:', navigator.language);

		/*
		if (typeof this.g.localPrefs.language === "undefined") {
			let navLang = navigator.language || navigator.userLanguage;
			let clientLang = navLang.split("-")[0];
			// CHECK IF CLIENT LANGUAGE IS AVAILABLE. OTHERWISE SET "IT" AS DEFAULT LANGUAGE
			if (this.g.catalogLanguages.includes(clientLang)) {
				this.g.localPrefs.language = clientLang;
			} else {
				this.g.localPrefs.language = this.env.language;
			}
		}
		*/

		this.darkmode = this.g.localPrefs.darkMode;
		if (typeof this.darkmode === "undefined") {
			this.darkmode = this.env.darkMode;
			this.g.localPrefs.darkMode = this.env.darkMode;
		}

		if (typeof this.g.localPrefs.catNavStyle === "undefined") {
			this.g.localPrefs.catNavStyle = this.env.catNavStyle;
		}
		if (typeof this.g.localPrefs.famNavStyle === "undefined") {
			this.g.localPrefs.famNavStyle = this.env.famNavStyle;
		}
		if (typeof this.g.localPrefs.prodNavStyle === "undefined") {
			this.g.localPrefs.prodNavStyle = this.env.prodNavStyle;
		}
		if (typeof this.g.localPrefs.navStyle === "undefined") {
			this.g.localPrefs.navStyle = this.env.navStyle;
		}

		if (!this.g.appPrefs.webMode) {
			this.g.appPrefs.firstTime = this.g.localPrefs.latestSync === undefined ? true : false;
			this.g.appPrefs.firstCount = 0;
			this.g.localPrefs.latestSync;
			this.g.appPrefs.navStyle = this.env.navStyle;
		} else {
			this.g.appPrefs.firstTime = false;
			this.g.appPrefs.firstCount = 0;
			this.g.localPrefs.latestSync;
			this.g.appPrefs.navStyle = this.env.navStyle;
		}

		this.storage.setLocalPrefs(this.g.localPrefs);
	}

	goTo(loc: string) {
		this.search.selectedItem = "";
		this.search.cancelFilter();
		if (loc === "welcome") {
			this.navCtrl.navigateBack("/" + loc);
		} else {
			this.navCtrl.navigateForward("/" + loc);
		}
		this.menuCtrl.close();
	}

	openBrowser(page: string) {
		if (page.includes("esseoquattro.it")) {
			this.iab.create(page, "_blank");
		} else {
			this.navCtrl.navigateForward(["/web", page]);
		}
		this.menuCtrl.close();
	}

	async onChangeLanguage(event) {
		const popover = await this.popoverCtrl.create({
			component: LanguagepopoverPage,
			cssClass: "uiPrefPopover",
			event: event,
			translucent: false,
		});
		await popover.present();
	}

	changeFullscreen() {
		if (this.fullscreenBrowser === true) {
			document.documentElement.requestFullscreen();
		} else {
			document.exitFullscreen();
		}
	}

	setDarkMode(e) {
		this.g.changeDarkMode(e.detail.checked);
	}
}
