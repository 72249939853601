import { HttpErrorResponse, HttpHeaderResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NavController, ToastController } from '@ionic/angular';
import { DocumentData } from 'angularfire2/firestore';
import { FirebaseService } from 'src/app/services/firebase.service';
import { GService } from 'src/app/services/g.service';
import { StorageService } from 'src/app/services/storage.service';

@Component({
	selector: 'app-info',
	templateUrl: './info.page.html',
	styleUrls: ['./info.page.scss'],
})
export class InfoPage implements OnInit {
	requested: any[] = [];
	previousProds: DocumentData[] = [];
	quotationForm: FormGroup;
	products: DocumentData[] = [];
	prodsFields: { [code: string]: { img?: string; select: boolean } } = {};
	successToast: HTMLIonToastElement;
	errorToast: HTMLIonToastElement;
	constructor(private g: GService, private router: Router, private fb: FormBuilder, private fire: FirebaseService, private storage: StorageService, private navCtrl: NavController, public toastCtrl: ToastController) { }

	ngOnInit() {
		this.quotationForm = this.fb.group({
			reference: new FormControl("", [Validators.required]),
			address: new FormControl("", [Validators.required]),
			tel: new FormControl("", []),
			email: new FormControl("", [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,6}$')]),
			note: new FormControl("", []),
		});
		this.prodsFields = {};
		this.initProducts();
	}

	initProducts() {
		this.requested = this.storage.getRequested();
		this.products = [];
		if (this.requested !== null) {
			this.requested.forEach((pref) => {
				this.products.push(this.fire.getProductDataByCode(pref.id));
			});
		}

		for (const p of this.products) {
			this.readProductsImg(p.immagine, p.codice);
			this.prodsFields[p.codice] = { select: true };
		}
	}

	async readProductsImg(prodImg, code) {
		this.fire.readImage(prodImg.id).then(
			(resImg: string) => {
				this.prodsFields[code].img = resImg;
			},
			(err) => {
				console.error("SIDE GET PROD IMAGE ERROR: ", err);
			}
		);
	}

	async sendQuotation() {
		if (this.successToast) {
			this.successToast.dismiss();
		}
		this.successToast = await this.toastCtrl.create({
			message: "Richiesta inviata con successo!",
			duration: 5000,
			color: "success",
			position: "middle",
		});

		if (this.errorToast) {
			this.errorToast.dismiss();
		}
		this.errorToast = await this.toastCtrl.create({
			message: "Impossibile inviare la richiesta. Prego riprovare.",
			duration: 5000,
			color: "warning",
			position: "middle",
		});

		let prods: string = "";
		for (let i = 0; i < this.products.length; i++) {
			let index = i + 1;
			prods = prods + "<br/>" + index + ") " + this.products[i].nome['it'] + " - " + this.products[i].stampa['it'];
			if(this.requested[i].gram) {
				prods = prods + " - " + this.requested[i].gram;
				if (this.requested[i].form) {
					prods = prods + " - " + this.requested[i].form;
				}
			}
		}
		const formData = new FormData();
		formData.append("reference", this.quotationForm.value.reference);
		formData.append("address", this.quotationForm.value.address);
		formData.append("tel", this.quotationForm.value.tel);
		formData.append("email", this.quotationForm.value.email);
		formData.append("note", this.quotationForm.value.note);
		formData.append("prod", JSON.stringify(prods));
		this.g.sendMail(formData).subscribe(
			(result: HttpHeaderResponse) => {
				this.successToast.present();
				this.quotationForm.reset();
			},
			(error: HttpErrorResponse) => {
				console.log("EMAILSENDER ERROR: ", error);
				this.errorToast.present();
			}
		);
	}

	removePref(index) {
		this.storage.removeRequested(index);
		this.requested.splice(index, 1);
		this.initProducts();
	}

	back() {
		this.navCtrl.back();
	}

	navigateInfo(index) {
		let id = this.requested[index].id;
		this.navCtrl.navigateForward("/info/" + id);
	}
}
