import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { StorageService } from "src/app/services/storage.service";
import { GService } from "src/app/services/g.service";
import { FirebaseService } from "src/app/services/firebase.service";
import { DocumentData } from "angularfire2/firestore";
import { AnimationController, createAnimation, NavController } from "@ionic/angular";
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';

@Component({
	selector: "app-main",
	templateUrl: "./main.page.html",
	styleUrls: ["./main.page.scss"],
})
export class MainPage implements OnInit {
	hasScrollbar: Boolean = false;
	menuDoc: DocumentData[] = [];
	menuIcons: { [id: string]: { icon: string } } = {};
	sliders: any[] = [];
	contentReady: boolean = false;

	@ViewChild('slides', { static: false }) slides: any;
	@ViewChild('mContent', { static: false }) mContent: any;
	constructor(private animCtrl: AnimationController, private router: Router, private storage: StorageService, private g: GService, private fire: FirebaseService, private navCtrl: NavController, private iab: InAppBrowser) { }

	get catalogUnavailable() {
		return this.g.appPrefs.catalogUnavailable;
	}

	ngOnInit() {
		this.initMenu();
	}

	async ionViewDidEnter() {
		if (!this.contentReady) {
			await this.initSlide();
			const animation = this.animCtrl.create().addElement(this.slides.nativeElement).duration(500).fromTo('opacity', '0', '1');
			animation.play();
		}
	}

	ionViewWillEnter() {
		this.checkForScrollbar();
	}

	goTo(loc: string) {
		this.navCtrl.navigateForward([loc]);
	}

	initMenu() {
		this.menuDoc = this.fire.getSettingsMenuData("gestione menu");
		this.menuDoc.forEach(menu => {
			this.menuIcons[menu.url['it']] = { icon: "" };
			this.fire.readImage(menu.bg.id).then((resImg: string) => {
				this.menuIcons[menu.url['it']].icon = resImg;
			}, (err) => {
			});
		});
	}

	initSlide() {
		const slidersDocs = this.fire.getSettingsImagesData("gestione immagini");
		this.fire.readImageArray(slidersDocs.main_slideshow).then(
			(resImgs: string[]) => {
				this.sliders = resImgs;
				this.contentReady = true;
			},
			(err) => {
				console.error("MAIN GET IMAGE ERROR: ", err);
			}
		);
	}

	openBrowser(page: string) {
		if (page.includes('esseoquattro.it')) {
			this.iab.create(page, '_blank');
		} else {
			this.navCtrl.navigateForward(["/web", page]);
		}

	}

	checkForScrollbar() {
		this.mContent.getScrollElement().then((scrollElement) => {
			this.hasScrollbar = (scrollElement.scrollHeight > scrollElement.clientHeight);
		});
	}

	back() {
		this.navCtrl.back();
	}
}
