import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { WelcomePage } from './pages/welcome/welcome.page';
import { MainPage } from './pages/main/main.page';
import { WebPage } from './pages/web/web.page';
import { SyncPage } from './pages/sync/sync.page';
import { IndexPage } from './pages/index/index.page';
import { CatPage } from './pages/cat/cat.page';
import { ProdPage } from './pages/prod/prod.page';
import { PrefPage } from './pages/pref/pref.page';
import { InfoPage } from './pages/info/info.page';
import { InfoprodPage } from './pages/info/infoprod/infoprod.page';
import { SearchPage } from './pages/search/search.page';

const routes: Routes = [
  {  path: '', redirectTo: '/welcome', pathMatch: 'full' },
  { path: 'welcome', component: WelcomePage },
  { path: 'sync', component: SyncPage},
  { path: 'main', component: MainPage},
  { path: 'web/:page', component: WebPage},
  { path: 'index', component: IndexPage},
  { path: 'cat', component: CatPage},
  { path: 'prod', component: ProdPage},
  { path: 'pref', component: PrefPage},
  { path: 'info', component: InfoPage},
  { path: 'info/:id', component: InfoprodPage},
  { path: 'search/:search', component: SearchPage},
  { path: 'find', component: SearchPage},
  { path: 'keyboard', loadChildren: () => import('./pages/keyboard/keyboard.module').then( m => m.KeyboardPageModule)},
  { path: 'search', loadChildren: () => import('./pages/search/search.module').then( m => m.SearchPageModule)},
];

@NgModule({
  imports: [
    // RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
