import { Component, OnInit } from "@angular/core";
import { LoadingController, NavController } from "@ionic/angular";
import { FirebaseService } from "src/app/services/firebase.service";
import { StorageService } from "src/app/services/storage.service";
import { GService } from "src/app/services/g.service";
import { Router } from "@angular/router";
import { Network } from '@ionic-native/network/ngx';
import { TranslatePipe } from '../../pipes/translate.pipe';
import { InitService } from "src/app/services/init.service";

@Component({
	selector: "app-sync",
	templateUrl: "./sync.page.html",
	styleUrls: ["./sync.page.scss"],
})
export class SyncPage implements OnInit {
	img: string;
	downloadProgress = 0;
	barColor;
	onSync = false;
	onDownload = false;
	errorSync = false;
	successSync = false;
	syncText = "";
	sliders: any[] = [];

	constructor(private network: Network, private navCtrl: NavController, private fire: FirebaseService, private storage: StorageService,
		private g: GService, private router: Router, private translate: TranslatePipe, private init: InitService, public loadingController: LoadingController) {
		this.storage.downloadProgressChange.subscribe((value) => {
			this.downloadProgress = value;
		});
	}

	ngOnInit() {
		this.onSync = false;
		this.onDownload = false;
		this.syncText = "";
		this.barColor = "medium";
		if (!this.g.appPrefs.firstTime) {
			this.initSlide();
		}
	}

	back(e: Event) {
		e.preventDefault();
		// e.stopPropagation();
		this.storage.resetProgress();
		this.navCtrl.back();
	}

	async syncronize() {
		this.syncText = this.translate.transform('data-sync');
		this.onSync = true;
		this.barColor = "medium";
		this.errorSync = false;
		this.storage.resetProgress();
		this.fire.downloadServerData().subscribe({
			next: async () => {
				this.syncText = this.translate.transform('file-sync');
				this.barColor = "medium";
				this.onDownload = true;
				await this.fire.downloadImages().then(
					async (downloadRes) => {
						if (!this.g.hasSync && !this.g.appPrefs.firstTime) {
							this.g.hasSync = true;
							this.syncronize();
						} else {
							console.log("SYNC: DOWNLOAD ALL IMAGES FROM SERVER SUCCESSFULLY");
							this.barColor = "success";
							this.fire.disableNetwork();
							const now = new Date(Date.now());
							this.g.localPrefs.latestSync = now.toLocaleDateString() + " - " + now.toLocaleTimeString();
							this.storage.setLocalPrefs(this.g.localPrefs);
							this.onSync = false;
							this.g.appPrefs.firstTime = false;
							this.g.appPrefs.catalogUnavailable = false;
							this.successSync = true;
						}
					},
					(err: Error) => {
						this.errorSync = true;
						this.fire.disableNetwork();
						this.onSync = false;
						this.syncText = "1- " + this.translate.transform('sync-error-message') + err;
						// this.t['sync-error-message'][this.g.localPrefs.language];

						this.barColor = "danger";
						console.error(err);
					}
				).catch((err) => {
					this.errorSync = true;
					this.fire.disableNetwork();
					this.onSync = false;
					this.syncText = "2- " + this.translate.transform('sync-error-message') + err;
					// this.t['sync-error-message'][this.g.localPrefs.language];
					this.barColor = "danger";
					console.error(err);
				});
			},
			error: async (err) => {
				this.errorSync = true;
				this.fire.disableNetwork();
				this.onSync = false;
				this.syncText = "3- " + this.translate.transform('sync-error-message') + err;
				//this.t['sync-error-message'][this.g.localPrefs.language];
				this.barColor = "danger";
				console.error(err);
			},
		});
	}

	async initSlide() {
		const slidersDocs = this.fire.getSettingsImagesData("gestione immagini");
		await this.fire.readImageArray(slidersDocs.sync_slideshow).then(
			(resImgs: string[]) => {
				this.sliders = resImgs;
			},
			(err) => {
				console.log("SYNC GET IMAGE ERROR: ", err);
			}
		);
	}

	resetApp() {
		if (this.g.appPrefs.os === 'iOS') {
			this.init.presentLoading();
			this.init.initializeApp();
		} else {
			document.location.href = "index.html";
		}
	}
}
