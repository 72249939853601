import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { SyncPage } from './sync.page';
import { TranslatePipe } from '../../pipes/translate.pipe';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule
  ],
  declarations: [SyncPage, TranslatePipe],
  exports: [TranslatePipe],
  providers: [TranslatePipe],
})
export class SyncPageModule {}
