import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { Router, Event, NavigationEnd } from "@angular/router";
import { NavController } from "@ionic/angular";
import Keyboard from "simple-keyboard";
import { GService } from "src/app/services/g.service";
import { SearchService } from "src/app/services/search.service";
import { StorageService } from "src/app/services/storage.service";

@Component({
	selector: "app-keyboard",
	templateUrl: "./keyboard.page.html",
	encapsulation: ViewEncapsulation.None,
	styleUrls: ["./keyboard.page.scss", "../../../assets/css/keyboard.scss"],
})
export class KeyboardPage implements OnInit {
	constructor(private g: GService, private search: SearchService, private navCtrl: NavController, private router: Router) { }
	searchString = '';
	keyboard: Keyboard;

	location: string;

	ngOnInit() {
		this.location = this.router.url;
	}

	ionViewWillEnter() {
		if (this.location.includes("categories")) {
			this.keyboard = new Keyboard("keyboardCat", {
				onChange: (input) => this.onChange(input),
				onKeyPress: (button) => this.onKeyPress(button),
			});
		} else if (this.location.includes("families")) {
			this.keyboard = new Keyboard("keyboardFam", {
				onChange: (input) => this.onChangeFams(input),
				onKeyPress: (button) => this.onKeyPressFams(button),
			});
		} else if (this.location.includes("products")) {
			this.keyboard = new Keyboard("keyboardProd", {
				onChange: (input) => this.onChangeProds(input),
				onKeyPress: (button) => this.onKeyPressProds(button),
			});
		}
	}

	ionViewDidLeave() {
		this.keyboard.destroy();
	}

	// FILTER CATEGORIES
	onKeyPress = (button: string) => {
		if (button === "{shift}" || button === "{lock}") {
			this.handleShift();
		} else if (button === "{enter}") {
			/* handle enter button*/
			this.toSearch();
		}
	};

	onInputChange = (event: any) => {
		this.keyboard.setInput(event.target.value);
	};

	handleShift = () => {
		const currentLayout = this.keyboard.options.layoutName;
		const shiftToggle = currentLayout === "default" ? "shift" : "default";
		this.keyboard.setOptions({
			layoutName: shiftToggle,
		});
	};

	onChange = (input: string) => {
		this.search.searchCategory = input;
		this.searchString = input;
	};

	// FILTER FAMILIES
	onKeyPressFams = (button: string) => {
		if (button === "{shift}" || button === "{lock}") {
			this.handleShiftFams();
		} else if (button === "{enter}") {
			/* handle enter button*/
			this.toSearch();
		}
	};

	onInputChangeFams = (event: any) => {
		this.keyboard.setInput(event.target.value);
	};

	handleShiftFams = () => {
		const currentLayout = this.keyboard.options.layoutName;
		const shiftToggle = currentLayout === "default" ? "shift" : "default";
		this.keyboard.setOptions({
			layoutName: shiftToggle,
		});
	};

	onChangeFams = (input: string) => {
		this.search.searchFamily = input;
		this.searchString = input;
	};

	// FILTER PRODUCTS
	onKeyPressProds = (button: string) => {
		if (button === "{shift}" || button === "{lock}") {
			this.handleShiftProds();
		} else if (button === "{enter}") {
			console.log('ENTER');
			this.toSearch();
		}
	};

	onInputChangeProds = (event: any) => {
		this.keyboard.setInput(event.target.value);
	};

	handleShiftProds = () => {
		const currentLayout = this.keyboard.options.layoutName;
		const shiftToggle = currentLayout === "default" ? "shift" : "default";
		this.keyboard.setOptions({
			layoutName: shiftToggle,
		});
	};

	onChangeProds = (input: string) => {
		this.search.searchTerm = input;
		this.searchString = input;
	};

	back() {
		if (!this.g.appPrefs.bMobile && this.location.includes('products')) {
			this.g.appPrefs.navStyle = this.g.localPrefs.navStyle;
		} else if (!this.g.appPrefs.bMobile && this.location.includes('families')) {
			this.g.appPrefs.navStyle = this.g.localPrefs.navStyle;
		}
		this.search.searchCategory = "";
		this.search.searchFamily = "";
		this.search.selectedItem = "";
		this.search.searchTerm = "";
		this.search.cancelFilter();
		this.search.cancelCatsFilter();
		this.search.cancelFamsFilter();
		this.navCtrl.back();
	}

	toSearch() {
		this.search.searchCategory = "";
		this.search.searchFamily = "";
		this.search.selectedItem = "";
		this.search.searchTerm = "";
		this.search.cancelFilter();
		this.search.cancelCatsFilter();
		this.search.cancelFamsFilter();

		this.navCtrl.navigateForward("/search/" + this.searchString);
		this.searchString = '';
	}
}
