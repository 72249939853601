import { Component, OnInit, Inject, ViewChild } from "@angular/core";
import { FirebaseService } from "src/app/services/firebase.service";
import { ActivatedRoute, Router } from "@angular/router";
import { GService } from "src/app/services/g.service";
import { DocumentData } from "angularfire2/firestore";
import { createAnimation, NavController } from "@ionic/angular";
import { SearchService } from "src/app/services/search.service";
import { DOCUMENT } from "@angular/common";
import { StorageService } from "src/app/services/storage.service";

@Component({
	selector: "app-side",
	templateUrl: "./catside.page.html",
	styleUrls: ["./catside.page.scss"],
})
export class CatSidePage implements OnInit {
	classDoc: DocumentData;
	docs: any;
	displayedDoc: any;
	catsImg: { [id: string]: { img: string; slides: string[]; available: boolean } } = {};
	classId: string;
	catId: string;
	famId: string;
	level: string;
	img: string;
	showMobSearch = false;
	hoverRow: number;
	productsAvailable: boolean[] = [];
	contentReady: boolean = false;

	pageTitle: string = "";

	segment: string;
	onInitSlide = true;
	internalSlideOpts = {
		loop: false,
		allowTouchMove: true,
		grabCursor: true,
		spaceBetween: 0,
		zoom: false,
		lazy: false,
		updateOnImagesReady: false,
	};

	searchString = "";

	@ViewChild("searchCatBar", { static: false }) searchCatBar: any;
	@ViewChild("searchFamBar", { static: false }) searchFamBar: any;

	constructor(
		private storage: StorageService,
		private fire: FirebaseService,
		private route: ActivatedRoute,
		private g: GService,
		private router: Router,
		private navCtrl: NavController,
		private search: SearchService,
		@Inject(DOCUMENT) private document: HTMLDocument
	) {}

	ngOnInit() {}

	async ionViewWillEnter() {
		this.route.params.subscribe((param) => {
			this.classId = param.class;
			this.catId = param.cat;
		});
		if (this.catId === "-") {
			this.level = "cat";
			this.g.appPrefs.navStyle = this.g.localPrefs.navStyle;
			this.segment = this.g.localPrefs.navStyle;
			this.initClass();
		} else {
			this.level = "fam";
			this.g.appPrefs.navStyle = this.g.localPrefs.navStyle;
			this.segment = this.g.localPrefs.navStyle;
			this.initCat();
		}
	}

	async ionViewDidEnter() {
		if (!this.contentReady) {
			if (this.level === "cat") {
				for (const c of this.docs) {
					await this.readClassImg(c.immagine, c.id, c);
					this.catsImg[c.id].available = true;
					const anim = createAnimation()
						.addElement(document.querySelectorAll(".uiSwiperFade" + c.id))
						.duration(500)
						.fromTo("opacity", "0", "1");
					anim.play();
				}
				this.contentReady = true;
			} else if (this.level === "fam") {
				for (const f of this.docs) {
					await this.readClassImg(f.immagine, f.id, f);
					this.catsImg[f.id].available = true;
					const anim = createAnimation()
						.addElement(document.querySelectorAll(".uiSwiperFade" + f.id))
						.duration(500)
						.fromTo("opacity", "0", "1");
					anim.play();
				}
				this.contentReady = true;

				this.search.displayedFams.forEach((fam) => {
					this.productsAvailable.push(this.checkIfProducts(fam.id));
				});
			}
		}
	}

	ionViewWillLeave() {
		this.search.cancelCatsFilter();
		this.search.cancelFamsFilter();
		this.search.cancelFilter();
	}

	async initClass() {
		this.storage.setImageTags([]);
		const classDoc = this.fire.getCategoryData(this.classId);
		this.pageTitle = classDoc.titolo[this.g.localPrefs.language];
		this.classDoc = classDoc;
		this.docs = classDoc.categorie;
		this.docs.sort((a, b) => {
			if (a.priorita > b.priorita) {
				return -1;
			}
			if (a.priorita < b.priorita) {
				return 1;
			}
			return 0;
		});
		this.search.setCategories(this.docs);
		/*
		for (const c of this.docs) {
			await this.readClassImg(c.immagine, c.id, c);
		}
		*/
	}

	async initCat() {
		const classDoc = this.fire.getCategoryData(this.classId);
		const catFilter = classDoc.categorie.filter((cat) => {
			return cat.id === this.catId;
		});
		this.docs = catFilter[0].famiglie;
		this.pageTitle =
			classDoc.titolo[this.g.localPrefs.language] + " - " + catFilter[0].nome[this.g.localPrefs.language];

		this.docs.sort((a, b) => {
			if (a.priorita > b.priorita) {
				return -1;
			}
			if (a.priorita < b.priorita) {
				return 1;
			}
			return 0;
		});
		this.search.setFamilies(this.docs);
		/*
		for (const f of this.docs) {
			await this.readClassImg(f.immagine, f.id, f);
		}
		this.search.displayedFams.forEach((fam) => {
			this.productsAvailable.push(this.checkIfProducts(fam.id));
		});
		*/
	}

	async readClassImg(classImg, id, doc) {
		this.onInitSlide = true;
		this.catsImg[id] = { img: "", slides: [], available: false };
		this.catsImg[id].slides = [];
		if (doc.slides) {
			for (let i = 0; i < doc.slides.length; i++) {
				await this.fire.readImage(doc.slides[i].id).then(
					(resSlide: string) => {
						this.catsImg[id].slides.push(resSlide);
					},
					(err) => {}
				);
			}
		}
		await this.fire.readImage(classImg.id).then(
			(resImg: string) => {
				this.catsImg[id].img = resImg;
				this.catsImg[id].slides.push(resImg);
			},
			(err) => {}
		);
	}

	navigateCat(catId) {
		if (this.search.selectedItem !== catId) {
			this.showMobSearch = false;

			// this.search.searchCategory = '';
			// this.search.searchFamily = '';
			this.catId = catId;
			if (this.g.appPrefs.bMobile === false) {
				this.navCtrl
					.navigateForward(
						[
							"/cat",
							{
								outlets: {
									SideRouter: "side/" + this.classId + "/" + "-",
									MainRouter: "main/" + this.classId + "/" + this.catId + "/" + "-",
								},
							},
						],
						{ skipLocationChange: true, replaceUrl: true }
					)
					.then(() => {
						this.search.selectedItem = catId;
					});
			} else {
				this.navCtrl.navigateForward([
					"/cat",
					{ outlets: { SideRouter: "main/" + this.classId + "/" + this.catId + "/" + "-" } },
				]);
			}
		} else {
			if (this.g.appPrefs.bMobile === false) {
				this.search.selectedItem = "";
				this.navCtrl.navigateBack(
					[
						"/cat",
						{
							outlets: {
								SideRouter: "side/" + this.classId + "/" + "-",
								MainRouter: "categories",
							},
						},
					],
					{ skipLocationChange: true, replaceUrl: true }
				);
			}
		}
	}

	navigateCatWithSkip(catId) {
		this.showMobSearch = false;
		this.search.selectedItem = "";
		this.search.searchCategory = "";
		this.search.searchFamily = "";
		this.catId = catId;
		if (this.g.appPrefs.bMobile === false) {
			this.navCtrl.navigateForward([
				"/cat",
				{
					outlets: {
						SideRouter: "side/" + this.classId + "/" + this.catId,
						MainRouter: "families",
					},
				},
			]);
		} else {
			this.navCtrl.navigateForward(["/cat", { outlets: { SideRouter: "side/" + this.classId + "/" + this.catId } }]);
		}
	}

	navigateFam(famId) {
		if (this.search.selectedItem !== famId) {
			this.showMobSearch = false;
			// this.search.searchCategory = '';
			// this.search.searchFamily = '';
			this.search.selectedItem = famId;
			this.famId = famId;
			if (this.g.appPrefs.bMobile === false) {
				this.navCtrl
					.navigateForward(
						[
							"/cat",
							{
								outlets: {
									SideRouter: "side/" + this.classId + "/" + this.catId,
									MainRouter: "main/" + this.classId + "/" + this.catId + "/" + this.famId,
								},
							},
						],
						{ skipLocationChange: true, replaceUrl: true }
					)
					.then(() => {
						this.search.selectedItem = famId;
					});
			} else {
				this.navCtrl.navigateForward([
					"/cat",
					{ outlets: { SideRouter: "main/" + this.classId + "/" + this.catId + "/" + this.famId } },
				]);
			}
		} else {
			if (this.g.appPrefs.bMobile === false) {
				this.search.selectedItem = "";
				this.navCtrl.navigateBack(
					[
						"/cat",
						{
							outlets: {
								SideRouter: "side/" + this.classId + "/" + this.catId,
								MainRouter: "families",
							},
						},
					],
					{ skipLocationChange: true, replaceUrl: true }
				);
			}
		}
	}

	navigateFamWithSkip(famId) {
		this.showMobSearch = false;
		this.search.selectedItem = "";
		this.search.searchCategory = "";
		this.search.searchFamily = "";
		this.famId = famId;
		this.navCtrl.navigateForward([
			"/prod",
			{
				outlets: {
					SideRouter: "side/" + this.classId + "/" + this.catId + "/" + this.famId,
					MainRouter: "products",
				},
			},
		]);
	}

	back() {
		this.showMobSearch = false;
		this.search.selectedItem = "";
		this.navCtrl.back();
	}

	checkIfProducts(famId) {
		// controllo se prodotti disponibili
		const products = this.fire.getProducts(this.classId, this.catId, famId);
		if (products.length > 0) {
			return true;
		}
		return false;
	}

	setHover(event, i) {
		this.hoverRow = event.type == "mouseover" ? i : null;
	}

	focusSearch() {
		if (this.level === "cat") {
			setTimeout(() => {
				this.searchCatBar.setFocus();
			}, 500);
		}
		if (this.level === "cat") {
			setTimeout(() => {
				this.searchFamBar.setFocus();
			}, 500);
		}
	}

	onInputSearch() {
		if (this.level === "cat") {
			this.searchString = this.search.searchCategory;

			console.log(this.searchString, this.search.searchCategory);
		} else if (this.level === "fam") {
			this.searchString = this.search.searchFamily;
		}
	}

	toSearch() {
		this.search.searchCategory = "";
		this.search.searchFamily = "";
		this.search.selectedItem = "";
		this.search.searchTerm = "";
		this.search.cancelFilter();
		this.search.cancelCatsFilter();
		this.search.cancelFamsFilter();
		this.showMobSearch = false;

		this.navCtrl.navigateForward("/search/" + this.searchString);
		this.searchString = "";
	}
}
