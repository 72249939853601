import { Component, OnInit, ViewChild } from '@angular/core';
import { FirebaseService } from 'src/app/services/firebase.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NavController, ModalController, PopoverController } from '@ionic/angular';
import { GService } from 'src/app/services/g.service';
import { DocumentData, DocumentReference } from 'angularfire2/firestore';
import { SearchService } from 'src/app/services/search.service';
import { VideomodalPage } from 'src/app/utility/videomodal/videomodal.page';
import { File } from '@ionic-native/file/ngx';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { StorageService } from 'src/app/services/storage.service';
import { YoutubemodalPage } from 'src/app/utility/youtubemodal/youtubemodal.page';
declare let window: any;

@Component({
  selector: 'app-main',
  templateUrl: './infoprod.page.html',
  styleUrls: ['./infoprod.page.scss'],
})
export class InfoprodPage implements OnInit {
  @ViewChild('contentInfo', { static: false }) content: any;
  @ViewChild('title', { static: false }) title: any;
  hasScrollbar: Boolean = false;

  prodId: string;
  prodDoc: DocumentData;
  prodUsage: any[] = [];
  localsDocs: DocumentData;
  images: string[] = [];
  imagesTags: string[] = [];
  document: string;
  selectedGrammage = '';
  selectedFormat = '';
  grammages: { gram: string, formats: { format: string, confs: string[] }[] }[] = [];
  formats: { format: string, confs: string[] }[] = [];
  allFormats: { format: string, available: boolean }[] = [];
  allConfs: { conf: string, available: boolean }[] = [];

  constructor(private fire: FirebaseService, private route: ActivatedRoute, private navCtrl: NavController, private router: Router,
    private g: GService, private search: SearchService, public modalController: ModalController,
    private file: File, private opener: FileOpener, private storage: StorageService,
    public popoverCtrl: PopoverController) { }

  ngOnInit() {
    this.route.params.subscribe((param) => {
      this.prodId = param.id;
      this.initProd();
      this.initLocals();
    });
  }

  ionViewDidEnter() {
    this.checkForScrollbar();
  }

  async initProd() {
    this.prodDoc = this.fire.getProductDataByCode(this.prodId);
    this.imagesTags = this.storage.getImageTags();
    if (this.imagesTags && this.imagesTags.length > 0) {
      this.fire.readImageArrayWithTags(this.prodDoc.slides).then((resImgs: string[]) => {
        this.images = resImgs;
      }, (err) => {
      });
    } else {
      this.fire.readImageArray(this.prodDoc.slides).then((resImgs: string[]) => {
        this.images = resImgs;
      }, (err) => {
      });
    }
    this.fire.readImage(this.prodDoc.immagine.id).then((resImg: string) => {
      if (!this.images.includes(resImg))
        this.images.push(resImg);
    }, (err) => {
    });
    this.createGrammageArray();
    this.initUsage();
  }

  initLocals() {
    this.fire.getLocalsData();
  }

  back() {
    let layout = this.g.localPrefs.navStyle;
    if (!this.g.appPrefs.bMobile && layout === 'list') {
      this.g.appPrefs.navStyle = this.g.localPrefs.navStyle;
    }
    this.search.selectedItem = '';
    this.search.searchTerm = '';
    this.g.selectedProd = '';
    this.grammages = [];
    this.search.cancelFilter();
    this.navCtrl.back();
  }

  async openVideo() {
    const modal = await this.modalController.create({
      component: VideomodalPage,
      cssClass: "uiDialog",
      componentProps: {
        videoID: this.prodDoc.video[this.g.localPrefs.language].id,
      },
    });
    return await modal.present();
  }

  async openYTVideo() {
    const modal = await this.modalController.create({
      component: YoutubemodalPage,
      cssClass: "uiDialog",
      componentProps: {
        videoURL: this.prodDoc.youtube[this.g.localPrefs.language],
      },
    });
    return await modal.present();
  }

  async openDoc() {
    if (!this.g.appPrefs.bMobile) {
      if (!this.g.appPrefs.webMode) {
        const name = this.fire.getMediaName(this.prodDoc.pdf[this.g.localPrefs.language].id);
        this.fire.readDocument(this.prodDoc.pdf[this.g.localPrefs.language].id).then(
          async (resDoc: any) => {
            const fileBlob: Blob = await this.fire.convertBase64ToBlob(resDoc, "data:application/pdf;base64");
            const fileURL = window.URL.createObjectURL(fileBlob);
            const a = document.createElement("a");
            document.body.appendChild(a);
            a.href = fileURL;
            a.download = name;
            a.target = "_blank";
            a.click();
          },
          (err) => {
            console.log('MAIN: DOC DOES NOT EXISTS');
          }
        );
      } else {
        this.fire.readDocument(this.prodDoc.pdf[this.g.localPrefs.language].id).then(
          async (resDoc: any) => {
            console.log(resDoc);
            window.open(resDoc, '_blank');
          },
          (err) => {
            console.log('MAIN: DOC DOES NOT EXISTS');
          }
        );
      }
    } else {
      if (!this.g.appPrefs.webMode) {
        const name = this.fire.getMediaName(this.prodDoc.pdf[this.g.localPrefs.language].id);
        let directory;
        if (this.g.appPrefs.os === 'Android') { // ############################## ANDROID GET MEDIA DIRECTORY
          directory = this.file.externalApplicationStorageDirectory;
        } else if (this.g.appPrefs.os === 'iOS') { // ############################## IOS GET MEDIA DIRECTORY
          directory = this.file.documentsDirectory;
        }
        this.opener.open(directory + '/documents/' + name, 'application/pdf')
          .catch((err) => {
            console.log('Error opening pdf file', err);
          });
      } else {
        this.fire.readDocument(this.prodDoc.pdf[this.g.localPrefs.language].id).then(
          async (resDoc: any) => {
            console.log(resDoc);
            window.open(resDoc, '_blank');
          },
          (err) => {
            console.log('MAIN: DOC DOES NOT EXISTS');
          }
        );
      }
    }
  }

  initUsage() {
    this.prodUsage = [];
    this.prodDoc.categoria.forEach(category => {
      this.prodUsage.push(this.fire.getCategoryFamByCode(category.doc_id.id, category.cat));
    });
  }

  createGrammageArray() {
    this.grammages = [];
    const grammature = JSON.stringify(this.prodDoc.grammature);
    const jsonObj = JSON.parse(grammature);
    const gramKeys = Object.keys(jsonObj);
    for (const gramkey of gramKeys) {
      const form: { format: string, confs: string[] }[] = [];
      let confections: string[] = [];
      const formatKeys = Object.keys(jsonObj[gramkey]);
      for (const formKey of formatKeys) {
        Object.keys(jsonObj).forEach((value) => {
          confections = Object.values(jsonObj[gramkey][formKey]);
        });
        for (const conft of confections) {
          const cIndex = this.allConfs.findIndex(c => c.conf === conft);
          if (cIndex < 0) {
            const conf = { conf: conft, available: false };
            this.allConfs.push(conf);
          }
        }
        form.push({ format: formKey, confs: confections });
        const fIndex = this.allFormats.findIndex(f => f.format === formKey);
        if (fIndex < 0) {
          const format = { format: formKey, available: false };
          this.allFormats.push(format);
        }
      }
      this.grammages.push({ gram: gramkey, formats: form });
    }
  }

  selectGrammage(gram) {
    this.selectedGrammage = gram;
    this.formats = [];
    this.selectedFormat = '';
    this.allFormats.forEach(f => {
      f.available = false;
    });
    this.allConfs.forEach(c => {
      c.available = false;
    });
    let gramIndex;
    for (let g = 0; g < this.grammages.length; g++) {
      if (this.grammages[g].gram === gram) {
        gramIndex = g;
      }
    }
    this.formats = this.grammages[gramIndex].formats;
    this.formats.forEach(f => {
      const checkFormat = { format: f.format, available: false };
      const fIndex = this.allFormats.findIndex(form => form.format === checkFormat.format);
      if (fIndex > -1) {
        this.allFormats[fIndex].available = true;
      }
    });
  }

  selectFormat(form) {
    let confs = [];
    this.selectedFormat = form;
    this.allConfs.forEach(c => {
      c.available = false;
    });
    this.formats.forEach(f => {
      if (f.format === form) {
        confs = f.confs;
      }
    });
    confs.forEach(c => {
      const checkConf = { conf: c, available: false };
      const cIndex = this.allConfs.findIndex(conf => conf.conf === checkConf.conf);
      if (cIndex > -1) {
        this.allConfs[cIndex].available = true;
      }
    });
  }

  checkForScrollbar() {
    this.content.getScrollElement().then((scrollElement) => {
      this.hasScrollbar = (scrollElement.scrollHeight > scrollElement.clientHeight);
    });
  }

  scrollToBottom() {
    this.content.scrollToPoint(0, this.title.nativeElement.offsetTop, 1000);
  }
}
