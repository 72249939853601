import { Component, OnInit } from "@angular/core";
import { PopoverController } from "@ionic/angular";
import { GService } from "src/app/services/g.service";
import { StorageService } from "src/app/services/storage.service";
import * as env from "src/environments/env.json";
@Component({
	selector: "app-languagepopover",
	templateUrl: "./languagepopover.page.html",
	styleUrls: ["./languagepopover.page.scss"],
})
export class LanguagepopoverPage implements OnInit {
	env: any = (env as any).default;
	countries = this.env.countries as Array<any>;

	constructor(private g: GService, private storage: StorageService, private popoverCtrl: PopoverController) {}

	ngOnInit() {}

	changeLanguage(lang) {
		this.g.localPrefs.language = lang;
		this.storage.setLocalPrefs(this.g.localPrefs);
		this.popoverCtrl.dismiss();
	}
}
