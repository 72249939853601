import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { CatPage } from './cat.page';
import { CatSidePage } from './catside/catside.page';
import { CatMainPage } from './catmain/catmain.page';
import { KeyboardPage } from '../keyboard/keyboard.page';

const routes: Routes = [
  { path: 'cat', component: CatPage, children: [
    { path: 'side/:class/:cat', component: CatSidePage, outlet: 'SideRouter'},
    { path: 'main/:class/:cat/:fam', component: CatMainPage, outlet: 'SideRouter'},
    { path: 'main/:class/:cat/:fam', component: CatMainPage, outlet: 'MainRouter'},
    { path: 'categories', component: KeyboardPage, outlet: 'MainRouter'},
    { path: 'families', component: KeyboardPage, outlet: 'MainRouter'}
  ]},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CatPageRoutingModule {}
