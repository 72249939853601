import { Injectable } from "@angular/core";
import { AngularFirestore, CollectionReference, DocumentData } from "angularfire2/firestore";
import { Observable } from "rxjs";
import { StorageService } from "./storage.service";
import { File } from "@ionic-native/file/ngx";
import { GService } from "./g.service";
declare let navigator: any;
declare let window: any;
const Typesense = require("typesense");

export interface SearchCategory {
	clas: any;
	id: any;
	categories: any[];
}

@Injectable({ providedIn: "root" })
export class FirebaseService {
	// COLLECTION REFERENCES
	classCollection: CollectionReference;
	productCollection: CollectionReference;
	localCollection: CollectionReference;
	mediaCollection: CollectionReference;
	settingCollection: CollectionReference;
	typesenseCollection: any;
	// COLLECTIONS' DOCUMENT DATA
	categoryData: DocumentData[] = [];
	productData: DocumentData[] = [];
	settingData: DocumentData[] = [];
	localData: DocumentData[] = [];
	mediaData: DocumentData[] = [];
	private listenOnlineData = false;
	private storageAvailable = true;

	constructor(
		private firebase: AngularFirestore,
		private storage: StorageService,
		private file: File,
		private g: GService
	) {
		this.file = new File();
		// FIREBASE SETUP
		this.firebase.firestore.settings({ cacheSizeBytes: -1 });
		// FOR CACHE DATA SAVE
		this.firebase.firestore.enablePersistence({ synchronizeTabs: true }).catch((err) => {
			if (err) {
				console.error("Error on enable persistence " + err);
			}
		});
		this.firebase.firestore.enableNetwork();
		// SET COLLECTIONS
		this.classCollection = this.firebase.firestore
			.collection("cataloghi")
			.doc("esseoquattro")
			.collection("classificazioni");
		this.productCollection = this.firebase.firestore.collection("cataloghi").doc("esseoquattro").collection("prodotti");
		this.mediaCollection = this.firebase.firestore.collection("cataloghi").doc("esseoquattro").collection("media");
		this.settingCollection = this.firebase.firestore
			.collection("cataloghi")
			.doc("esseoquattro")
			.collection("impostazioni generali");
		this.localCollection = this.firebase.firestore.collection("cataloghi").doc("esseoquattro").collection("locale");
	}

	// ############################## DISABLE FIRESTORE NETWORK
	disableNetwork() {
		this.firebase.firestore.disableNetwork();
	}

	// ############################## GET CACHE DATA
	getCacheData(): Observable<boolean | Error> {
		console.log("Trying to get data from cache...");
		return new Observable((observer) => {
			this.getCategoriesCache().subscribe({
				next: (categories) => {
					this.categoryData = categories;
					console.log("Get cache categories: SUCCESS");
					this.getProductsCache().subscribe({
						next: (products) => {
							this.productData = products;
							console.log("Get cache products: SUCCESS");
							this.getLocalCache().subscribe({
								next: (locals) => {
									this.localData = locals;
									console.log("Get cache locals: SUCCESS");
									this.saveLocals();
									this.getSettingCache().subscribe({
										next: (settings) => {
											this.settingData = settings;
											console.log("Get cache settings: SUCCESS");
											this.saveSettings();
											this.getMediaCache().subscribe({
												next: (medias) => {
													this.mediaData = medias;
													console.log("Get cache medias: SUCCESS");
													this.checkFsDirectories().subscribe({
														next: (exists) => {
															console.log("Check fs folders: SUCCESS", exists);
															// this.storageAvailable = true;
															this.firebase.firestore.disableNetwork();
															observer.next();
															observer.complete();
														},
														error: (err: Error) => {
															console.error("Check fs folders: ERROR", err);
															this.mediaData = [];
															// this.storageAvailable = false;
															observer.error(err);
															observer.complete();
														},
													});
												},
												error: (err: Error) => {
													console.error("Get cache medias: ERROR");
													observer.error(err);
													observer.complete();
												},
											});
										},
										error: (err: Error) => {
											console.error("Get cache locals: ERROR");
											observer.error(err);
											observer.complete();
										},
									});
								},
								error: (err: Error) => {
									console.error("Get cache settings: ERROR");
									observer.error(err);
									observer.complete();
								},
							});
						},
						error: (err: Error) => {
							console.error("Get cache products: ERROR");
							observer.error(err);
							observer.complete();
						},
					});
				},
				error: (err: Error) => {
					console.error("Get cache categories: ERROR");
					observer.error(err);
					observer.complete();
				},
			});
		});
	}

	// CHECK IF FS DIRECTORY ALREADY EXISTS (BUG FIX)
	checkFsDirectories(): Observable<boolean> {
		return new Observable((observer) => {
			if (this.g.appPrefs.os === "browser") {
				if (this.g.appPrefs.browser === "Firefox") {
					// ############################## BROWSER - FIREFOX CHECK DIRECTORY
					window.requestFileSystem(window.PERSISTENT, 5 * 1024 * 1024, (fs) => {
						fs.root.getDirectory(
							"images",
							{
								create: false,
							},
							(imgDir) => {
								// fs.root.getDirectory("videos", {
								//     create: false
								// }, (vidDir) => {
								fs.root.getDirectory(
									"documents",
									{
										create: false,
									},
									(docDir) => {
										observer.next(true);
										observer.complete();
									},
									(docDirErr) => {
										observer.error(docDirErr);
										observer.complete();
									}
								);
								// }, (vidDirErr) => {
								//     observer.error(vidDirErr);
								//     observer.complete();
								// });
							},
							(imgDirErr) => {
								observer.error(imgDirErr);
								observer.complete();
							}
						);
					});
				} else {
					// ############################## BROWSER - CHROME CHECK DIRECTORY
					navigator.webkitPersistentStorage.requestQuota(1024 * 1024 * 1024, (grantedBytes) => {
						window.webkitRequestFileSystem(window.PERSISTENT, 5 * 1024 * 1024, (fs) => {
							fs.root.getDirectory(
								"images",
								{
									create: false,
								},
								(imgDir) => {
									// fs.root.getDirectory("videos", {
									//     create: false
									// }, (vidDir) => {
									fs.root.getDirectory(
										"documents",
										{
											create: false,
										},
										(docDir) => {
											observer.next(true);
											observer.complete();
										},
										(docDirErr) => {
											observer.error(docDirErr);
											observer.complete();
										}
									);
									// }, (vidDirErr) => {
									//     observer.error(vidDirErr);
									//     observer.complete();
									// });
								},
								(imgDirErr) => {
									observer.error(imgDirErr);
									observer.complete();
								}
							);
						});
					});
				}
			} else if (this.g.appPrefs.os === "Android") {
				// ############################## ANDROID CHECK DIRECTORY
				this.file.resolveDirectoryUrl(this.file.externalApplicationStorageDirectory).then((fs) => {
					fs.getDirectory(
						"images",
						{
							create: false,
						},
						(imgDir) => {
							// fs.getDirectory("videos", {
							//     create: false
							// }, (vidDir) => {
							fs.getDirectory(
								"documents",
								{
									create: false,
								},
								(docDir) => {
									observer.next(true);
									observer.complete();
								},
								(docDirErr) => {
									observer.error(docDirErr);
									observer.complete();
								}
							);
							// }, (vidDirErr) => {
							//     observer.error(vidDirErr);
							//     observer.complete();
							// });
						},
						(imgDirErr) => {
							observer.error(imgDirErr);
							observer.complete();
						}
					);
				});
			} else if (this.g.appPrefs.os === "iOS") {
				// ############################## IOS CHECK DIRECTORY
				this.file.resolveDirectoryUrl(this.file.documentsDirectory).then((fs) => {
					fs.getDirectory(
						"images",
						{
							create: false,
						},
						(imgDir) => {
							// fs.getDirectory("videos", {
							//     create: false
							// }, (vidDir) => {
							fs.getDirectory(
								"documents",
								{
									create: false,
								},
								(docDir) => {
									observer.next(true);
									observer.complete();
								},
								(docDirErr) => {
									observer.error(docDirErr);
									observer.complete();
								}
							);
							// }, (vidDirErr) => {
							//     observer.error(vidDirErr);
							//     observer.complete();
							// });
						},
						(imgDirErr) => {
							observer.error(imgDirErr);
							observer.complete();
						}
					);
				});
			}
		});
	}

	// GET CATEGORIES DATA FROM CACHE
	getCategoriesCache(): Observable<DocumentData[]> {
		return new Observable((observer) => {
			let docData: DocumentData[] = [];
			this.classCollection.get({ source: "cache" }).then((querySnapshot) => {
				if (querySnapshot.empty) {
					docData = [];
					observer.error(new Error("Categories cache is empty"));
					observer.complete();
				} else {
					querySnapshot.forEach((doc) => {
						docData.push(doc);
					});
					observer.next(docData);
					observer.complete();
				}
			});
		});
	}

	// GET PRODUCTS DATA FROM CACHE
	getProductsCache(): Observable<DocumentData[]> {
		return new Observable((observer) => {
			let docData: DocumentData[] = [];
			this.productCollection.get({ source: "cache" }).then((querySnapshot) => {
				if (querySnapshot.empty) {
					docData = [];
					observer.error(new Error("Products cache is empty"));
					observer.complete();
				} else {
					querySnapshot.forEach((doc) => {
						docData.push(doc);
					});
					observer.next(docData);
					observer.complete();
				}
			});
		});
	}

	// GET SETTINGS DATA FROM CACHE
	getSettingCache(): Observable<DocumentData[]> {
		return new Observable((observer) => {
			let docData: DocumentData[] = [];
			this.settingCollection.get({ source: "cache" }).then((querySnapshot) => {
				if (querySnapshot.empty) {
					docData = [];
					observer.error(new Error("Settings cache is empty"));
					observer.complete();
				} else {
					querySnapshot.forEach((doc) => {
						docData.push(doc);
					});
					observer.next(docData);
					observer.complete();
				}
			});
		});
	}

	// GET LOCALS DATA FROM CACHE
	getLocalCache(): Observable<DocumentData[]> {
		return new Observable((observer) => {
			let docData: DocumentData[] = [];
			this.localCollection.get({ source: "cache" }).then((querySnapshot) => {
				if (querySnapshot.empty) {
					docData = [];
					observer.error(new Error("Categories cache is empty"));
					observer.complete();
				} else {
					querySnapshot.forEach((doc) => {
						docData.push(doc);
					});
					observer.next(docData);
					observer.complete();
				}
			});
		});
	}

	// SAVE SETTINGS DATA IN GLOBAL SERVICE
	saveSettings() {
		const stringIndex = this.settingData.findIndex((doc) => doc.id === "gestione stringhe");
		this.g.texts = {};
		this.settingData[stringIndex].data().stringhe.forEach((el) => {
			this.g.texts[el.id] = {
				text: el.testo,
			};
		});
	}

	saveLocals() {
		this.g.prodFields = {};
		this.localData.forEach((doc) => {
			this.g.prodFields[doc.id] = {
				text: doc.data(),
			};
		});
	}

	// GET MEDIAS DATA FROM CACHE
	getMediaCache(): Observable<DocumentData[]> {
		return new Observable((observer) => {
			let docData: DocumentData[] = [];
			this.mediaCollection.get({ source: "cache" }).then((querySnapshot) => {
				if (querySnapshot.empty) {
					docData = [];
					observer.error(new Error("Media cache is empty"));
					observer.complete();
				} else {
					querySnapshot.forEach((doc) => {
						docData.push(doc);
					});
					observer.next(docData);
					observer.complete();
				}
			});
		});
	}

	// ############################## SERVER DATA
	downloadServerData() {
		console.log("Trying to get data from server...");
		this.firebase.firestore.enableNetwork();
		return new Observable((observer) => {
			this.downloadCategoriesDocs().subscribe({
				next: (categories) => {
					this.categoryData = categories;
					console.log("Download categories from server: SUCCESS");
					this.downloadProductsDocs().subscribe({
						next: (products) => {
							this.productData = products;
							console.log("Download products from server: SUCCESS");
							this.downloadLocalsDocs().subscribe({
								next: (locals) => {
									this.localData = locals;
									this.saveLocals();
									console.log("Download locals from server: SUCCESS");
									this.downloadSettingsDocs().subscribe({
										next: (settings) => {
											this.settingData = settings;
											this.saveSettings();
											console.log("Download settings from server: SUCCESS");
											observer.next();
											observer.complete();
										},
										error: (err: Error) => {
											console.error("Download settings from server: ERROR");
											this.firebase.firestore.disableNetwork();
											observer.error(err);
											observer.complete();
										},
									});
								},
								error: (err: Error) => {
									console.error("Download locals from server: ERROR");
									this.firebase.firestore.disableNetwork();
									observer.error(err);
									observer.complete();
								},
							});
						},
						error: (err: Error) => {
							console.error("Download products from server: ERROR");
							this.firebase.firestore.disableNetwork();
							observer.error(err);
							observer.complete();
						},
					});
				},
				error: (err: Error) => {
					console.error("Download categories from server: ERROR");
					this.firebase.firestore.disableNetwork();
					observer.error(err);
					observer.complete();
				},
			});
		});
	}

	getWebData() {
		console.log("Trying to get data from server...");
		this.firebase.firestore.enableNetwork();
		return new Observable((observer) => {
			this.downloadCategoriesDocs().subscribe({
				next: (categories) => {
					this.categoryData = categories;
					console.log("Download categories from server: SUCCESS");
					this.downloadProductsDocs().subscribe({
						next: (products) => {
							this.productData = products;
							console.log("Download products from server: SUCCESS");
							this.downloadLocalsDocs().subscribe({
								next: (locals) => {
									this.localData = locals;
									this.saveLocals();
									console.log("Download locals from server: SUCCESS");
									this.downloadSettingsDocs().subscribe({
										next: (settings) => {
											this.settingData = settings;
											this.saveSettings();
											console.log("Download settings from server: SUCCESS");
											this.downloadMediaDocs().subscribe({
												next: (medias) => {
													this.mediaData = medias;
													console.log("Download medias from server: SUCCESS");
													observer.next();
													observer.complete();
												},
												error: (err: Error) => {
													console.error("Download medias from server: ERROR");
													this.firebase.firestore.disableNetwork();
													observer.error(err);
													observer.complete();
												},
											});
										},
										error: (err: Error) => {
											console.error("Download settings from server: ERROR");
											this.firebase.firestore.disableNetwork();
											observer.error(err);
											observer.complete();
										},
									});
								},
								error: (err: Error) => {
									console.error("Download locals from server: ERROR");
									this.firebase.firestore.disableNetwork();
									observer.error(err);
									observer.complete();
								},
							});
						},
						error: (err: Error) => {
							console.error("Download products from server: ERROR");
							this.firebase.firestore.disableNetwork();
							observer.error(err);
							observer.complete();
						},
					});
				},
				error: (err: Error) => {
					console.error("Download categories from server: ERROR");
					this.firebase.firestore.disableNetwork();
					observer.error(err);
					observer.complete();
				},
			});
		});
	}

	// DOWNLOAD CATEGORIES DATA FROM SERVER
	downloadCategoriesDocs(): Observable<DocumentData[]> {
		return new Observable((observer) => {
			let docData: DocumentData[] = [];
			this.classCollection
				.get({ source: "server" })
				.then((querySnapshot) => {
					if (querySnapshot.empty) {
						docData = [];
						observer.error(new Error("Category document is empty"));
						observer.complete();
					} else {
						querySnapshot.forEach((doc) => {
							docData.push(doc);
						});
						observer.next(docData);
						observer.complete();
					}
				})
				.catch((error) => {
					observer.error(error);
					observer.complete();
				});
		});
	}

	// DOWNLOAD PRODUCTS DATA FROM SERVER
	downloadProductsDocs(): Observable<DocumentData[]> {
		return new Observable((observer) => {
			let docData: DocumentData[] = [];
			this.productCollection
				.get({ source: "server" })
				.then((querySnapshot) => {
					if (querySnapshot.empty) {
						docData = [];
						observer.error(new Error("Products document is empty"));
						observer.complete();
					} else {
						querySnapshot.forEach((doc) => {
							docData.push(doc);
						});
						observer.next(docData);
						observer.complete();
					}
				})
				.catch((error) => {
					observer.error(error);
					observer.complete();
				});
		});
	}

	// DOWNLOAD SETTINGS DATA FROM SERVER
	downloadSettingsDocs(): Observable<DocumentData[]> {
		return new Observable((observer) => {
			let docData: DocumentData[] = [];
			this.settingCollection
				.get({ source: "server" })
				.then((querySnapshot) => {
					if (querySnapshot.empty) {
						docData = [];
						observer.error(new Error("Settings document is empty"));
						observer.complete();
					} else {
						querySnapshot.forEach((doc) => {
							docData.push(doc);
						});
						observer.next(docData);
						observer.complete();
					}
				})
				.catch((error) => {
					observer.error(error);
					observer.complete();
				});
		});
	}

	// DOWNLOAD LOCALS DATA FROM SERVER
	downloadLocalsDocs(): Observable<DocumentData[]> {
		return new Observable((observer) => {
			let docData: DocumentData[] = [];
			this.localCollection
				.get({ source: "server" })
				.then((querySnapshot) => {
					if (querySnapshot.empty) {
						docData = [];
						observer.error(new Error("Local document is empty"));
						observer.complete();
					} else {
						querySnapshot.forEach((doc) => {
							docData.push(doc);
						});
						observer.next(docData);
						observer.complete();
					}
				})
				.catch((error) => {
					observer.error(error);
					observer.complete();
				});
		});
	}

	// DOWNLOAD MEDIA DATA FROM SERVER
	downloadMediaDocs(): Observable<DocumentData[]> {
		return new Observable((observer) => {
			let docData: DocumentData[] = [];
			this.mediaCollection
				.get({ source: "server" })
				.then((querySnapshot) => {
					if (querySnapshot.empty) {
						docData = [];
						observer.error(new Error("Media document is empty"));
						observer.complete();
					} else {
						querySnapshot.forEach((doc) => {
							docData.push(doc);
						});
						observer.next(docData);
						observer.complete();
					}
				})
				.catch((error) => {
					observer.error(error);
					observer.complete();
				});
		});
	}

	// DOWNLOAD IMAGES FROM SERVER AND STORE IT ON LOCALSTORAGE
	async downloadImages(): Promise<any> {
		this.firebase.firestore.enableNetwork();
		// CHECK
		if (!this.g.appPrefs.firstTime) {
			return new Promise((resolve, reject) => {
				if (this.listenOnlineData) {
					resolve(Promise.all(this.mediaData));
				} else {
					// CREATE A SNAPSHOT THAT LISTEN SERVER CHAGES
					this.mediaCollection.onSnapshot(
						{
							includeMetadataChanges: true,
						},
						async (snap) => {
							const change = await snap.docChanges();
							console.log("CHANGE LENGTH:", change.length);
							if (change.length === 0) {
								if (this.g.appPrefs.bOffline) {
									reject(new Error("Cannot download all files"));
								} else {
									// this.storage.completeProgress();
									resolve(Promise.all(this.mediaData));
								}
							} else {
								this.storage.downloadSize = change.length;
								for (let i = 0; i < change.length; i++) {
									if (!change[i].doc.metadata.fromCache || this.g.appPrefs.firstTime) {
										if (change[i].type === "added") {
											await this.writeFile(change[i].doc).then(
												(res) => {
													this.mediaData.push(change[i].doc);
													this.storage.addProgress();
													console.log("MEDIA ADDED ID:", change[i].doc.id);
												},
												(err: Error) => {
													console.error("ERROR ON ADD ID:", change[i].doc.id, err);
													reject(err);
												}
											);
										} else if (change[i].type === "modified") {
											const index = this.mediaData.findIndex((item) => item.id === change[i].doc.id);
											await this.removeFile(change[i].doc).then(
												async () => {
													this.mediaData.splice(index, 1);
													await this.writeFile(change[i].doc).then(
														(res) => {
															this.mediaData.push(change[i].doc);
															console.log("MEDIA MODIFIED ID:", change[i].doc.id);
														},
														(err: Error) => {
															console.error("ERROR ON WRITE-MODIFY ID:", change[i].doc.id, err);
															reject(err);
														}
													);
												},
												(err: Error) => {
													console.error("ERROR ON MODIFY ID:", change[i].doc.id, err);
													reject(err);
												}
											);
										} else if (change[i].type === "removed") {
											console.log("REMOVE MEDIA");
											const index = this.mediaData.findIndex((item) => item.id === change[i].doc.id);
											await this.removeFile(change[i].doc).then(
												() => {
													console.log("MEDIA REMOVED ID:", change[i].doc.id);
													this.mediaData.splice(index, 1);
												},
												(err: Error) => {
													console.error("ERROR ON REMOVE ID:", change[i].doc.id, err);
													this.mediaData.splice(index, 1);
													reject(err);
												}
											);
										}
									} else {
										// IS NOT FIRST TIME && DATA FROM CACHE
										this.storage.addProgress();
									}
								}
								if (this.storage.downloadedFiles < this.storage.downloadSize) {
									reject(new Error("Cannot download all files"));
								} else {
									this.storage.completeProgress();
									this.listenOnlineData = true;
									resolve(Promise.all(this.mediaData));
								}
							}
						},
						(error) => {
							reject(error);
						}
					);
				}
			});
		} else {
			return new Promise((resolve, reject) => {
				if (this.listenOnlineData) {
					this.storage.completeProgress();
					resolve(Promise.all(this.mediaData));
				} else {
					this.mediaCollection
						.get({ source: "server" })
						.then(async (querySnapshot) => {
							if (querySnapshot.empty) {
								reject(new Error("Media document is empty"));
							} else {
								console.log("QUERY LENGTH:", querySnapshot.size);
								this.storage.downloadSize = querySnapshot.size;
								const queryDocs = querySnapshot.docs;
								let bError = false;
								for (let i = 0; i < queryDocs.length; i++) {
									if (i < this.g.appPrefs.firstCount) {
										this.storage.addProgress();
									} else {
										await this.writeFile(queryDocs[i])
											.then(
												(res) => {
													this.mediaData.push(queryDocs[i]);
													this.storage.addProgress();
													console.log("MEDIA ADDED ID:", queryDocs[i].id);
												},
												(err: Error) => {
													console.log("ERROR ON ADD ID:", queryDocs[i].id);
													bError = true;
													reject(err);
												}
											)
											.catch((error) => {
												console.log("ERROR ON FILE:", error);
												bError = true;
												reject(error);
											});
										if (bError) {
											this.g.appPrefs.firstCount = i;
											reject(new Error("WRITE FILE ERROR"));
											return false;
										}
									}
								}
								if (this.storage.downloadedFiles < this.storage.downloadSize) {
									reject(new Error("Cannot download all files"));
								} else {
									this.storage.completeProgress();
									this.listenOnlineData = true;
									resolve(Promise.all(this.mediaData));
								}
							}
						})
						.catch((error) => {
							reject(error);
						});
				}
			});
		}
	}

	// WRITE FILE IN LOCALSTORAGE
	async writeFile(doc: DocumentData): Promise<any> {
		const that = this;
		return new Promise((resolve, reject) => {
			try {
				const fileExt = /[.]/.exec(doc.data().url) ? /[^.]+$/.exec(doc.data().url)[0] : undefined;
				let fileFolder: string;
				let fileType: string;
				if (fileExt === "jpg" || fileExt === "jpeg") {
					fileFolder = "images";
					fileType = "data:image/jpeg;base64,";
				} else if (fileExt === "png") {
					fileFolder = "images";
					fileType = "data:image/png;base64,";
				} /*else if (fileExt === "mp4") {
                        fileFolder = "videos";
                        fileType = "data:video/mp4;base64,";
                    }*/ else if (fileExt === "pdf") {
					fileFolder = "documents";
					fileType = "data:application/pdf;base64,";
				} else if (fileExt === undefined) {
					console.log("WRONG FILE EXT:", fileExt);
					resolve(null);
				} else {
					console.log("WRONG FILE EXT:", fileExt);
					resolve(null);
				}
				const xhr = new XMLHttpRequest();
				const contType = xhr.getResponseHeader("content-type");
				xhr.open("GET", doc.data().url, true);
				xhr.responseType = "arraybuffer";

				xhr.onload = async function (e) {
					if (this.status === 200) {
						const uInt8Array = new Uint8Array(this.response);
						let i = uInt8Array.length;
						const biStr = new Array(i);
						while (i--) {
							biStr[i] = String.fromCharCode(uInt8Array[i]);
						}
						const data = biStr.join("");
						let encoded;
						if (fileExt === "pdf" && that.g.appPrefs.bMobile) {
							const base64 = fileType + window.btoa(data);
							encoded = await that.convertBase64ToBlob(base64, "data:application/pdf;base64");
						} else {
							encoded = fileType + window.btoa(data);
						}
						const name = doc.data().url.split("/").pop().split("#")[0].split("?")[0];
						const fileName = name;
						if (that.g.appPrefs.os === "browser") {
							if (that.g.appPrefs.browser === "Firefox") {
								// ############################## BROWSER - FIREFOX WRITE FILE
								window.requestFileSystem(
									window.PERSISTENT,
									5 * 1024 * 1024,
									(fs) => {
										fs.root.getDirectory(
											fileFolder,
											{
												create: true,
											},
											(dir) => {
												dir.getFile(
													fileName,
													{
														create: true,
														exclusive: false,
													},
													(fileEntry) => {
														fileEntry.createWriter(
															(fileWriter) => {
																fileWriter.write(encoded);
																fileWriter.onwriteend = (res) => {
																	resolve(res);
																};
																fileWriter.onerror = (err) => {
																	reject(err);
																};
															},
															(writeError) => {
																reject(writeError);
															}
														);
													},
													(getError) => {
														reject(getError);
													}
												);
											},
											(dirError) => {
												reject(dirError);
											}
										);
									},
									(urlError) => {
										reject(urlError);
									}
								);
							} else {
								// ############################## BROESER - CHROME WRITE FILE
								navigator.webkitPersistentStorage.requestQuota(1024 * 1024 * 1024, (grantedBytes) => {
									window.webkitRequestFileSystem(
										window.PERSISTENT,
										5 * 1024 * 1024,
										(fs) => {
											fs.root.getDirectory(
												fileFolder,
												{
													create: true,
												},
												(dir) => {
													dir.getFile(
														fileName,
														{
															create: true,
															exclusive: false,
														},
														(fileEntry) => {
															fileEntry.createWriter(
																(fileWriter) => {
																	fileWriter.write(encoded);
																	fileWriter.onwriteend = (res) => {
																		resolve(res);
																	};
																	fileWriter.onerror = (err) => {
																		reject(err);
																	};
																},
																(writeError) => {
																	reject(writeError);
																}
															);
														},
														(getError) => {
															reject(getError);
														}
													);
												},
												(dirError) => {
													reject(dirError);
												}
											);
										},
										(urlError) => {
											reject(urlError);
										}
									);
								});
							}
						} else if (that.g.appPrefs.os === "Android") {
							// ############################## ANDROID WRITE FILE
							that.file.resolveDirectoryUrl(that.file.externalApplicationStorageDirectory).then(
								(fs) => {
									fs.getDirectory(
										fileFolder,
										{
											create: true,
										},
										(dir) => {
											dir.getFile(
												fileName,
												{
													create: true,
													exclusive: false,
												},
												(fileEntry) => {
													fileEntry.createWriter(
														(fileWriter) => {
															fileWriter.write(encoded);
															fileWriter.onwriteend = (res) => {
																resolve(res);
															};
															fileWriter.onerror = (err) => {
																reject(err);
															};
														},
														(writeError) => {
															reject(writeError);
														}
													);
												},
												(getError) => {
													reject(getError);
												}
											);
										},
										(dirError) => {
											reject(dirError);
										}
									);
								},
								(urlError) => {
									reject(urlError);
								}
							);
						} else if (that.g.appPrefs.os === "iOS") {
							// ############################## IOS WRITE FILE
							// for IOS
							if (fileFolder !== undefined) {
								that.file.resolveDirectoryUrl(that.file.documentsDirectory).then(
									(fs) => {
										console.log("FF", fileFolder + " " + doc.id);
										fs.getDirectory(
											fileFolder,
											{
												create: true,
											},
											(dir) => {
												dir.getFile(
													fileName,
													{
														create: true,
														exclusive: false,
													},
													(fileEntry) => {
														fileEntry.createWriter(
															(fileWriter) => {
																fileWriter.write(encoded);
																fileWriter.onwriteend = (res) => {
																	resolve(res);
																};
																fileWriter.onerror = (err) => {
																	reject(err + " " + doc.id);
																};
															},
															(writeError) => {
																reject(writeError + " " + doc.id);
															}
														);
													},
													(getError) => {
														reject(getError + " " + doc.id);
													}
												);
											},
											(dirError) => {
												reject(dirError + " " + doc.id);
											}
										);
									},
									(urlError) => {
										reject(urlError + " " + doc.id);
									}
								);
							}
						}
					}
				};
				xhr.onreadystatechange = function (oEvent) {
					if (xhr.readyState === 4) {
						if (xhr.status !== 200) {
							reject("DOWNLOAD FILE: XHR ERROR " + doc.id);
						}
					}
				};
				xhr.send();
			} catch (e) {
				reject(new Error("Unable to download file " + doc.id));
			}
		});
	}

	// REMOVE FILE FROM LOCALSTORAGE
	async removeFile(doc: DocumentData): Promise<any> {
		const that = this;
		const fileExt = /[.]/.exec(doc.data().url) ? /[^.]+$/.exec(doc.data().url)[0] : undefined;
		let fileFolder: string;
		if (fileExt === "jpg" || fileExt === "png" || fileExt === "jpeg") {
			fileFolder = "images";
		} else if (fileExt === "mp4") {
			fileFolder = "videos";
		} else if (fileExt === "pdf") {
			fileFolder = "documents";
		} else if (fileExt === undefined) {
			console.log("WRONG FILE EXT:", fileExt);
			return null;
		} else {
			console.log("WRONG FILE EXT:", fileExt);
			return null;
		}
		const name = doc.data().url.split("/").pop().split("#")[0].split("?")[0];
		const fileName = name;
		return new Promise((resolve, reject) => {
			if (that.g.appPrefs.os === "browser") {
				if (that.g.appPrefs.browser === "Firefox") {
					// ############################## BROWSER - FIREFOX DELETE FILE
					navigator.webkitPersistentStorage.requestQuota(1024 * 1024 * 1024, (grantedBytes) => {
						window.webkitRequestFileSystem(window.PERSISTENT, 5 * 1024 * 1024, (fs) => {
							fs.root.getDirectory(
								fileFolder,
								{
									create: false,
								},
								(dir) => {
									dir.getFile(
										fileName,
										{
											create: false,
											exclusive: false,
										},
										(fileEntry) => {
											fileEntry.remove(
												() => {
													console.log("REMOVE FILE SUCCESS");
													resolve(true);
												},
												(removeError) => {
													console.error("REMOVEFILE ERROR:", removeError);
													reject(removeError);
												}
											);
										},
										(getError) => {
											console.error("REMOVEFILE GET-ERROR:", getError);
											reject(getError);
										}
									);
								},
								(dirError) => {
									console.error("REMOVEFILE DIRECTORY-ERROR:", dirError);
									reject(dirError);
								}
							);
						});
					});
				} else {
					// ############################## BROWSER - CHROME DELETE FILE
					window.requestFileSystem(window.PERSISTENT, 5 * 1024 * 1024, (fs) => {
						fs.root.getDirectory(
							fileFolder,
							{
								create: false,
							},
							(dir) => {
								dir.getFile(
									fileName,
									{
										create: false,
										exclusive: false,
									},
									(fileEntry) => {
										fileEntry.remove(
											() => {
												console.log("REMOVE FILE SUCCESS");
												resolve(true);
											},
											(removeError) => {
												console.error("REMOVEFILE ERROR:", removeError);
												reject(removeError);
											}
										);
									},
									(getError) => {
										console.error("REMOVEFILE GET-ERROR:", getError);
										reject(getError);
									}
								);
							},
							(dirError) => {
								console.error("REMOVEFILE DIRECTORY-ERROR:", dirError);
								reject(dirError);
							}
						);
					});
				}
			} else if (that.g.appPrefs.os === "Android") {
				// ############################## ANDROID DELETE FILE
				that.file.resolveDirectoryUrl(that.file.externalApplicationStorageDirectory).then(
					(fs) => {
						fs.getDirectory(
							fileFolder,
							{
								create: false,
							},
							(dir) => {
								dir.getFile(
									fileName,
									{
										create: true,
										exclusive: false,
									},
									(fileEntry) => {
										fileEntry.remove(
											() => {
												console.log("REMOVE FILE SUCCESS");
												resolve(true);
											},
											(removeError) => {
												console.error("REMOVEFILE ERROR:", removeError);
												reject(removeError);
											}
										);
									},
									(getError) => {
										console.error("REMOVEFILE GET-ERROR:", getError);
										reject(getError);
									}
								);
							},
							(dirError) => {
								console.error("REMOVEFILE DIRECTORY-ERROR:", dirError);
								reject(dirError);
							}
						);
					},
					(urlError) => {
						console.error("REMOVEFILE URL-ERROR:", urlError);
						reject(urlError);
					}
				);
			} else if (that.g.appPrefs.os === "iOS") {
				// ############################## IOS DELETE FILE
				// for IOS
				that.file.resolveDirectoryUrl(that.file.documentsDirectory).then(
					(fs) => {
						fs.getDirectory(
							fileFolder,
							{
								create: false,
							},
							(dir) => {
								dir.getFile(
									fileName,
									{
										create: true,
										exclusive: false,
									},
									(fileEntry) => {
										fileEntry.remove(
											() => {
												console.log("REMOVE FILE SUCCESS");
												resolve(true);
											},
											(removeError) => {
												console.error("REMOVEFILE ERROR:", removeError);
												reject(removeError);
											}
										);
									},
									(getError) => {
										console.error("REMOVEFILE GET-ERROR:", getError);
										reject(getError);
									}
								);
							},
							(dirError) => {
								console.error("REMOVEFILE DIRECTORY-ERROR:", dirError);
								reject(dirError);
							}
						);
					},
					(urlError) => {
						console.error("REMOVEFILE URL-ERROR:", urlError);
						reject(urlError);
					}
				);
			}
		});
	}

	// GET ALL CATEGORIES DATA
	getCategoriesData(): DocumentData[] {
		return this.categoryData;
	}

	// GET ALL PRODUCTS DATA
	getProductsData(): DocumentData[] {
		return this.productData;
	}

	// GET ALL SETTINGS DATA
	getSettigsData(): DocumentData[] {
		return this.settingData;
	}

	// GET ALL SETTINGS DATA
	getLocalsData(): DocumentData[] {
		return this.localData;
	}

	// GET SPECIFIC CATEGORY DATA
	getCategoryData(id: string): DocumentData {
		const catIndex = this.categoryData.findIndex((doc) => doc.id === id);
		const catDoc = this.categoryData[catIndex].data();
		return catDoc;
	}

	getCategoryFamByCode(classId: string, idCat: string) {
		const classIndex = this.categoryData.findIndex((doc) => doc.id === classId);
		const classData = this.categoryData[classIndex].data().categorie;
		const catIndex = classData.findIndex((cat) => cat.id === idCat);
		return classData[catIndex].nome;
	}

	// READ IMAGE FROM LOCALSTORAGE
	async readImage(id: string) {
		if (!this.g.appPrefs.webMode) {
			const that = this;
			let fileExt = "";
			const mediaIndex = this.mediaData.findIndex((doc) => doc.id === id);
			const mediaDoc = this.mediaData[mediaIndex].data();
			if (mediaDoc) {
				fileExt = /[.]/.exec(mediaDoc.url) ? /[^.]+$/.exec(mediaDoc.url)[0] : undefined;
			} else {
				console.log("No document Extension");
			}
			const fileName = mediaDoc.url.split("/").pop().split("#")[0].split("?")[0];
			return new Promise((resolve, reject) => {
				if (that.g.appPrefs.os === "browser") {
					if (this.g.appPrefs.browser === "Firefox") {
						// ############################## BROWSER - FIREFOX READ FILE
						window.requestFileSystem(
							window.PERSISTENT,
							5 * 1024 * 1024,
							(fs) => {
								fs.root.getDirectory(
									"images",
									{
										create: true,
									},
									(dir) => {
										dir.getFile(
											fileName,
											{
												create: false,
												exclusive: false,
											},
											(fileEntry) => {
												fileEntry.file((file) => {
													const reader = new FileReader();
													reader.readAsText(file);
													reader.onloadend = (event: any) => {
														resolve(event.target.result);
													};
													reader.onerror = (readError) => {
														console.error("READFILE ERROR:", readError);
														reject(readError);
													};
												});
											},
											(getError) => {
												console.error("READFILE GET-ERROR:", getError);
												reject(getError);
											}
										);
									},
									(dirError) => {
										console.error("READFILE DIRECTORY-ERROR:", dirError);
										reject(dirError);
									}
								);
							},
							(urlError) => {
								console.error("READFILE URL-ERROR:", urlError);
								reject(urlError);
							}
						);
					} else {
						// ############################## BROWSER - CHROME READ FILE
						//resolve('file:///images/' + fileName);
						navigator.webkitPersistentStorage.requestQuota(1024 * 1024 * 1024, (grantedBytes) => {
							window.webkitRequestFileSystem(
								window.PERSISTENT,
								5 * 1024 * 1024,
								(fs) => {
									fs.root.getDirectory(
										"images",
										{
											create: false,
										},
										(dir) => {
											dir.getFile(
												fileName,
												{
													create: false,
													exclusive: false,
												},
												(fileEntry) => {
													fileEntry.file((file) => {
														const reader = new FileReader();
														reader.readAsText(file);
														reader.onloadend = (event: any) => {
															resolve(event.target.result);
														};
														reader.onerror = (readError) => {
															console.error("READFILE ERROR:", readError);
															reject(readError);
														};
													});
												},
												(getError) => {
													console.error("READFILE GET-ERROR:", getError);
													reject(getError);
												}
											);
										},
										(dirError) => {
											console.error("READFILE DIRECTORY-ERROR:", dirError);
											reject(dirError);
										}
									);
								},
								(urlError) => {
									console.error("READFILE URL-ERROR:", urlError);
									reject(urlError);
								}
							);
						});
					}
				} else if (that.g.appPrefs.os === "Android") {
					// ############################## ANDROID READ FILE
					that.file.resolveDirectoryUrl(that.file.externalApplicationStorageDirectory).then(
						(fs) => {
							fs.getDirectory(
								"images",
								{
									create: false,
								},
								(dir) => {
									dir.getFile(
										fileName,
										{
											create: false,
											exclusive: false,
										},
										(fileEntry) => {
											fileEntry.file((file) => {
												const reader = new FileReader();
												reader.readAsText(file);
												reader.onloadend = (event: any) => {
													resolve(event.target.result);
												};
												reader.onerror = (readError) => {
													console.error("READFILE ERROR:", readError);
													reject(readError);
												};
											});
										},
										(getError) => {
											console.error("READFILE GET-ERROR:", getError);
											reject(getError);
										}
									);
								},
								(dirError) => {
									console.error("READFILE DIRECTORY-ERROR:", dirError);
									reject(dirError);
								}
							);
						},
						(urlError) => {
							console.error("READFILE URL-ERROR:", urlError);
							reject(urlError);
						}
					);
				} else if (that.g.appPrefs.os === "iOS") {
					// ############################## IOS READ FILE
					that.file.resolveDirectoryUrl(that.file.documentsDirectory).then(
						(fs) => {
							fs.getDirectory(
								"images",
								{
									create: false,
								},
								(dir) => {
									dir.getFile(
										fileName,
										{
											create: false,
											exclusive: false,
										},
										(fileEntry) => {
											fileEntry.file((file) => {
												const reader = new FileReader();
												reader.readAsText(file);
												reader.onloadend = (event: any) => {
													resolve(event.target.result);
												};
												reader.onerror = (readError) => {
													console.error("READFILE ERROR:", readError);
													reject(readError);
												};
											});
										},
										(getError) => {
											console.error("READFILE GET-ERROR:", getError);
											reject(getError);
										}
									);
								},
								(dirError) => {
									console.error("READFILE DIRECTORY-ERROR:", dirError);
									reject(dirError);
								}
							);
						},
						(urlError) => {
							console.error("READFILE URL-ERROR:", urlError);
							reject(urlError);
						}
					);
				}
			});
		} else {
			const mediaIndex = this.mediaData.findIndex((doc) => doc.id === id);
			const mediaDoc = this.mediaData[mediaIndex].data();
			return mediaDoc.url;
		}
	}

	// READ AD ARRAY OF MEDIA-DOCUMENT REFERENCE
	async readImageArray(immArray: any[]) {
		const resImages: any[] = [];
		const promises = [];
		return new Promise((resolve, reject) => {
			for (let i = 0; i < immArray.length; i++) {
				this.readImage(immArray[i].id).then(
					(imm) => {
						resImages.push(imm);
					},
					(err) => {
						reject(err);
					}
				);
			}
			resolve(resImages);
		});
	}

	// READ AD ARRAY OF MEDIA-DOCUMENT REFERENCE. RESULT DEPENDS OF TAG IMAGE FIELD
	async readImageArrayWithTags(immArray: any[]) {
		const imgTags: string[] = this.storage.getImageTags();
		const resImages: any[] = [];
		return new Promise((resolve, reject) => {
			for (let i = 0; i < immArray.length; i++) {
				const mediaIndex = this.mediaData.findIndex((doc) => doc.id === immArray[i].id);
				const mediaDocTags: string[] = this.mediaData[mediaIndex].data().tag;
				if (imgTags.some((tag) => mediaDocTags.includes(tag))) {
					this.readImage(immArray[i].id).then(
						(imm) => {
							resImages.push(imm);
						},
						(err) => {
							reject(err);
						}
					);
				}
			}
			resolve(resImages);
		});
	}

	// READ VIDEO FROM LOCALSTORAGE
	async readVideo(id: string) {
		if (!this.g.appPrefs.webMode) {
			const that = this;
			const mediaIndex = this.mediaData.findIndex((doc) => doc.id === id);
			const mediaDoc = this.mediaData[mediaIndex].data();
			const name = mediaDoc.url.split("/").pop().split("#")[0].split("?")[0];
			const fileName = name;
			return new Promise((resolve, reject) => {
				if (that.g.appPrefs.os === "browser") {
					if (this.g.appPrefs.browser === "Firefox") {
						// ############################## BROWSER - FIREFOX READ FILE
						window.requestFileSystem(
							window.PERSISTENT,
							5 * 1024 * 1024,
							(fs) => {
								fs.root.getDirectory(
									"videos",
									{
										create: true,
									},
									(dir) => {
										dir.getFile(
											fileName,
											{
												create: false,
												exclusive: false,
											},
											(fileEntry) => {
												fileEntry.file((file) => {
													const reader = new FileReader();
													reader.readAsText(file);
													reader.onloadend = (event: any) => {
														resolve(event.target.result);
													};
													reader.onerror = (readError) => {
														console.error("READVIDEO ERROR:", readError);
														reject(readError);
													};
												});
											},
											(getError) => {
												console.error("READVIDEO GET-ERROR:", getError);
												reject(getError);
											}
										);
									},
									(dirError) => {
										console.error("READVIDEO DIRECTORY-ERROR:", dirError);
										reject(dirError);
									}
								);
							},
							(urlError) => {
								console.error("READVIDEO URL-ERROR:", urlError);
								reject(urlError);
							}
						);
					} else {
						// ############################## BROWSER - CHROME READ FILE
						navigator.webkitPersistentStorage.requestQuota(1024 * 1024 * 1024, (grantedBytes) => {
							window.webkitRequestFileSystem(
								window.PERSISTENT,
								5 * 1024 * 1024,
								(fs) => {
									fs.root.getDirectory(
										"videos",
										{
											create: false,
										},
										(dir) => {
											dir.getFile(
												fileName,
												{
													create: false,
													exclusive: false,
												},
												(fileEntry) => {
													fileEntry.file((file) => {
														const reader = new FileReader();
														reader.readAsText(file);
														reader.onloadend = (event: any) => {
															resolve(event.target.result);
														};
														reader.onerror = (readError) => {
															console.error("READVIDEO ERROR:", readError);
															reject(readError);
														};
													});
												},
												(getError) => {
													console.error("READVIDEO GET-ERROR:", getError);
													reject(getError);
												}
											);
										},
										(dirError) => {
											console.error("READVIDEO DIRECTORY-ERROR:", dirError);
											reject(dirError);
										}
									);
								},
								(urlError) => {
									console.error("READVIDEO URL-ERROR:", urlError);
									reject(urlError);
								}
							);
						});
					}
				} else if (that.g.appPrefs.os === "Android") {
					// ############################## ANDROID READ FILE
					that.file.resolveDirectoryUrl(that.file.externalApplicationStorageDirectory).then(
						(fs) => {
							fs.getDirectory(
								"videos",
								{
									create: false,
								},
								(dir) => {
									dir.getFile(
										fileName,
										{
											create: false,
											exclusive: false,
										},
										(fileEntry) => {
											fileEntry.file((file) => {
												const reader = new FileReader();
												reader.readAsText(file);
												reader.onloadend = (event: any) => {
													resolve(event.target.result);
												};
												reader.onerror = (readError) => {
													console.error("READVIDEO ERROR:", readError);
													reject(readError);
												};
											});
										},
										(getError) => {
											console.error("READVIDEO GET-ERROR:", getError);
											reject(getError);
										}
									);
								},
								(dirError) => {
									console.error("READVIDEO DIRECTORY-ERROR:", dirError);
									reject(dirError);
								}
							);
						},
						(urlError) => {
							console.error("READVIDEO URL-ERROR:", urlError);
							reject(urlError);
						}
					);
				} else if (that.g.appPrefs.os === "iOS") {
					// ############################## IOS READ FILE
					that.file.resolveDirectoryUrl(that.file.documentsDirectory).then(
						(fs) => {
							fs.getDirectory(
								"videos",
								{
									create: false,
								},
								(dir) => {
									dir.getFile(
										fileName,
										{
											create: false,
											exclusive: false,
										},
										(fileEntry) => {
											fileEntry.file((file) => {
												const reader = new FileReader();
												reader.readAsText(file);
												reader.onloadend = (event: any) => {
													resolve(event.target.result);
												};
												reader.onerror = (readError) => {
													console.error("READVIDEO ERROR:", readError);
													reject(readError);
												};
											});
										},
										(getError) => {
											console.error("READVIDEO GET-ERROR:", getError);
											reject(getError);
										}
									);
								},
								(dirError) => {
									console.error("READVIDEO DIRECTORY-ERROR:", dirError);
									reject(dirError);
								}
							);
						},
						(urlError) => {
							console.error("READVIDEO URL-ERROR:", urlError);
							reject(urlError);
						}
					);
				}
			});
		} else {
			const mediaIndex = this.mediaData.findIndex((doc) => doc.id === id);
			const mediaDoc = this.mediaData[mediaIndex].data();
			return mediaDoc.url;
		}
	}

	// READ DOCUMENT FROM LOCALSTORAGE
	async readDocument(id: string) {
		if (!this.g.appPrefs.webMode) {
			const that = this;
			const mediaIndex = this.mediaData.findIndex((doc) => doc.id === id);
			const mediaDoc = this.mediaData[mediaIndex].data();
			const name = mediaDoc.url.split("/").pop().split("#")[0].split("?")[0];
			const fileName = name;
			return new Promise((resolve, reject) => {
				if (that.g.appPrefs.os === "browser") {
					if (this.g.appPrefs.browser === "Firefox") {
						// ############################## BROWSER - FIREFOX READ FILE
						window.requestFileSystem(
							window.PERSISTENT,
							5 * 1024 * 1024,
							(fs) => {
								fs.root.getDirectory(
									"documents",
									{
										create: true,
									},
									(dir) => {
										dir.getFile(
											fileName,
											{
												create: false,
												exclusive: false,
											},
											(fileEntry) => {
												fileEntry.file((file) => {
													const reader = new FileReader();
													reader.readAsText(file);
													reader.onloadend = (event: any) => {
														resolve(event.target.result);
													};
													reader.onerror = (readError) => {
														console.error("READDOCUMENT ERROR:", readError);
														reject(readError);
													};
												});
											},
											(getError) => {
												console.error("READDOCUMENT GET-ERROR:", getError);
												reject(getError);
											}
										);
									},
									(dirError) => {
										console.error("READDOCUMENT DIRECTORY-ERROR:", dirError);
										reject(dirError);
									}
								);
							},
							(urlError) => {
								console.error("READDOCUMENT URL-ERROR:", urlError);
								reject(urlError);
							}
						);
					} else {
						// ############################## BROWSER - CHROME READ FILE
						navigator.webkitPersistentStorage.requestQuota(1024 * 1024 * 1024, (grantedBytes) => {
							window.webkitRequestFileSystem(
								window.PERSISTENT,
								5 * 1024 * 1024,
								(fs) => {
									fs.root.getDirectory(
										"documents",
										{
											create: false,
										},
										(dir) => {
											dir.getFile(
												fileName,
												{
													create: false,
													exclusive: false,
												},
												(fileEntry) => {
													fileEntry.file((file) => {
														const reader = new FileReader();
														reader.readAsText(file);
														reader.onloadend = (event: any) => {
															resolve(event.target.result);
														};
														reader.onerror = (readError) => {
															console.error("READDOCUMENT ERROR:", readError);
															reject(readError);
														};
													});
												},
												(getError) => {
													console.error("READDOCUMENT GET-ERROR:", getError);
													reject(getError);
												}
											);
										},
										(dirError) => {
											console.error("READDOCUMENT DIRECTORY-ERROR:", dirError);
											reject(dirError);
										}
									);
								},
								(urlError) => {
									console.error("READDOCUMENT URL-ERROR:", urlError);
									reject(urlError);
								}
							);
						});
					}
				} else if (that.g.appPrefs.os === "Android") {
					// ############################## ANDROID READ FILE
					that.file.resolveDirectoryUrl(that.file.externalApplicationStorageDirectory).then(
						(fs) => {
							fs.getDirectory(
								"documents",
								{
									create: false,
								},
								(dir) => {
									dir.getFile(
										fileName,
										{
											create: false,
											exclusive: false,
										},
										(fileEntry) => {
											fileEntry.file((file) => {
												const reader = new FileReader();
												reader.readAsText(file);
												reader.onloadend = (event: any) => {
													resolve(event.target.result);
												};
												reader.onerror = (readError) => {
													console.error("READDOCUMENT ERROR:", readError);
													reject(readError);
												};
											});
										},
										(getError) => {
											console.error("READDOCUMENT GET-ERROR:", getError);
											reject(getError);
										}
									);
								},
								(dirError) => {
									console.error("READDOCUMENT DIRECTORY-ERROR:", dirError);
									reject(dirError);
								}
							);
						},
						(urlError) => {
							console.error("READDOCUMENT URL-ERROR:", urlError);
							reject(urlError);
						}
					);
				} else if (that.g.appPrefs.os === "iOS") {
					// ############################## IOS READ FILE
					that.file.resolveDirectoryUrl(that.file.documentsDirectory).then(
						(fs) => {
							fs.getDirectory(
								"documents",
								{
									create: false,
								},
								(dir) => {
									dir.getFile(
										fileName,
										{
											create: false,
											exclusive: false,
										},
										(fileEntry) => {
											fileEntry.file((file) => {
												const reader = new FileReader();
												reader.readAsText(file);
												reader.onloadend = (event: any) => {
													resolve(event.target.result);
												};
												reader.onerror = (readError) => {
													console.error("READDOCUMENT ERROR:", readError);
													reject(readError);
												};
											});
										},
										(getError) => {
											console.error("READDOCUMENT GET-ERROR:", getError);
											reject(getError);
										}
									);
								},
								(dirError) => {
									console.error("READDOCUMENT DIRECTORY-ERROR:", dirError);
									reject(dirError);
								}
							);
						},
						(urlError) => {
							console.error("READDOCUMENT URL-ERROR:", urlError);
							reject(urlError);
						}
					);
				}
			});
		} else {
			const mediaIndex = this.mediaData.findIndex((doc) => doc.id === id);
			const mediaDoc = this.mediaData[mediaIndex].data();
			return mediaDoc.url;
		}
	}

	// GET ALL PRODUCTS WITH CERTAIN CLASS, CATEGORY AND FAMILY
	getProducts(classe: string, categoria: string, famiglia: string) {
		const productArray: DocumentData[] = [];
		this.productData.forEach((prod) => {
			let index = 0;
			let findprod = false;

			const categ = prod.data().categoria;
			if (categ) {
				while (!findprod && index < categ.length) {
					if (categ[index].doc_id.id === classe && categ[index].cat === categoria && categ[index].fam === famiglia) {
						productArray.push(prod.data());
						findprod = true;
					} else {
						index += 1;
					}
				}
			} else {
				index += 1;
			}
		});
		return productArray;
	}

	// GET A SPECIFIC PRODUCT DATA BY CODE
	getProductDataByCode(code: string): DocumentData {
		const prodIndex = this.productData.findIndex((doc) => doc.data().codice === code);
		const prodDoc = this.productData[prodIndex].data();
		return prodDoc;
	}

	// GET A SPECIFIC PRODUCT DATA BY ID
	getProductDataByID(code: string): DocumentData {
		const prodIndex = this.productData.findIndex((doc) => doc.id === code);
		let prodDoc: DocumentData;
		if (this.productData[prodIndex]) {
			prodDoc = this.productData[prodIndex].data();
		} else {
			prodDoc = {};
		}
		return prodDoc;
	}

	// GET MENU SETTINGS DATA
	getSettingsMenuData(id: string) {
		const menuIndex = this.settingData.findIndex((doc) => doc.id === id);
		return this.settingData[menuIndex].data().menu;
	}

	// GET MENU IMAGES DATA
	getSettingsImagesData(id: string) {
		const menuIndex = this.settingData.findIndex((doc) => doc.id === id);
		return this.settingData[menuIndex].data();
	}

	// GET MEDIA NAME FROM URL FIELD
	getMediaName(id: string) {
		const mediaIndex = this.mediaData.findIndex((doc) => doc.id === id);
		return this.mediaData[mediaIndex].data().url.split("/").pop().split("#")[0].split("?")[0];
	}

	// CONVERTS A BASE64 STRING IN BLOB OBJECT
	async convertBase64ToBlob(b64Data, contentType): Promise<Blob> {
		const base64Response = await fetch(contentType + b64Data);
		return base64Response.blob();
	}

	/* GET TYPESENSE COLLECTION */
	getTypesense() {
		let client = new Typesense.Client({
			nodes: [
				{
					host: "typesense.esseoquattro.it", // For Typesense Cloud use xxx.a1.typesense.net
					port: "443", // For Typesense Cloud use 443
					protocol: "https", // For Typesense Cloud use https
				},
			],
			apiKey: "lPGhxJcUELchuTqI6CtG4fvvmsB0ZVMm",
			connectionTimeoutSeconds: 2,
		});

		client
			.collections("products")
			.documents()
			.export()
			.then((data: string) => {
				console.log("READ TYPESENSE COLLECTION SUCCESFULLY");
				data = data.replace(/(\r\n|\n|\r)/gm, ",");
				this.typesenseCollection = JSON.parse("[" + data + "]");
				//console.log(this.typesenseCollection);
			})
			.catch((reason) => {
				console.log("ERROR TYPESENSE COLLECTION", reason);
			});
	}

	/*RICERCA PRODOTTO IN BASE A STRINGA */
	searchProds(search: string, index: number): Observable<any> {
		return new Observable((observer) => {
			let result = {
				index: index,
				products: [],
			};
			let catched = 0;
			while (result.index < this.typesenseCollection.length && catched < 10) {
				if (
					this.typesenseCollection[result.index].nome[this.g.localPrefs.language]
						.toLowerCase()
						.includes(search.toLowerCase())
				) {
					result.products.push(this.typesenseCollection[result.index].id);
					catched += 1;
				}
				result.index += 1;
			}
			observer.next(result);
			observer.complete();
		});
	}

	/*
    searchProds(search: string, index: string): Observable<DocumentData[]> {
        return new Observable((observer) => {
            console.log('INDEX', index)
            let searchDoc: DocumentData[] = [];
            let queryName = this.productCollection.where(('nome.' + this.g.localPrefs.language).toLowerCase(), '>=', search).orderBy('nome.' + this.g.localPrefs.language).startAfter(index).limit(10);
            //let queryName = this.productCollection.orderBy('nome.' + this.g.localPrefs.language).where('nome.' + this.g.localPrefs.language, '>=', search).startAt(search);
            queryName.get().then(querySnapshot => {
                if (querySnapshot.empty) {
                    console.log('EMPTY');
                    observer.next(searchDoc);
                    observer.complete();
                } else {
                    querySnapshot.forEach((doc) => {
                        searchDoc.push(doc.data());
                    });
                    observer.next(searchDoc);
                    observer.complete();
                }
            }).catch((error) => {
                observer.error(error);
                observer.complete();
            });
        });
    }
    */

	/*RICERCA CAT/FAM IN BASE A STRINGA */
	searchCats(search: string): Observable<DocumentData[]> {
		return new Observable((observer) => {
			let searchDoc: SearchCategory[] = [];
			this.classCollection
				.get()
				.then((querySnaphot) => {
					querySnaphot.forEach((classification) => {
						let clas = classification.data().titolo[this.g.localPrefs.language];
						let classId = classification.id;
						let searchedCats: any[] = [];
						classification.data().categorie.forEach((category) => {
							let cat = category;
							let searchedFams: any[] = [];
							let queryFam: any[] = category.famiglie;
							queryFam.forEach((fam) => {
								if (fam.nome[this.g.localPrefs.language].toLowerCase().includes(search.toLowerCase())) {
									searchedFams.push(fam);
								}
							});

							if (searchedFams.length > 0) {
								searchedCats.push({
									cat: cat,
									families: searchedFams,
								});
							} else {
								if (cat.nome[this.g.localPrefs.language].toLowerCase().includes(search.toLowerCase())) {
									searchedCats.push({
										cat: cat,
										families: searchedFams,
									});
								}
							}
						});
						searchDoc.push({
							clas: clas,
							id: classId,
							categories: searchedCats,
						});
					});
					observer.next(searchDoc);
					observer.complete();
				})
				.catch((error) => {
					observer.error(error);
					observer.complete();
				});
			// this.classCollection.doc(clas).get().then(querySnapshot => {
			//     let queryCats: any[] = querySnapshot.data().categorie;
			//     queryCats.forEach(cat => {
			//         let queryFam: any[] = cat.famiglie;
			//         let searchedFams: any[] = [];
			//         queryFam.forEach(fam => {
			//             if (fam.nome[this.g.localPrefs.language].toLowerCase().includes(search.toLowerCase())) {
			//                 searchedFams.push(fam);
			//             }
			//         });
			//         if (searchedFams.length > 0) {
			//             searchDoc.push({ category: cat, families: searchedFams });
			//         } else {
			//             if (cat.nome[this.g.localPrefs.language].toLowerCase().includes(search.toLowerCase())) {
			//                 searchDoc.push({ category: cat, families: searchedFams });
			//             }
			//         }
			//     });
			//     observer.next(searchDoc);
			//     observer.complete();
			// }).catch((error) => {
			//     observer.error(error);
			//     observer.complete();
			// });
		});
	}
}
