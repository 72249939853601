import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { FCM } from "@ionic-native/fcm/ngx";
import { Device } from "@ionic-native/device/ngx";
import { Network } from "@ionic-native/network/ngx";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import {
	LoadingController,
	MenuController,
	NavController,
	Platform,
	PopoverController,
	ToastController,
} from "@ionic/angular";
import { FirebaseService } from "./firebase.service";
import { GService } from "./g.service";
import { StorageService } from "./storage.service";
import * as env from "src/environments/env.json";
import { ScreenOrientation } from "@ionic-native/screen-orientation/ngx";
import { DocumentData } from "angularfire2/firestore";

@Injectable({
	providedIn: "root",
})
export class InitService {
	constructor(
		private g: GService,
		private fireService: FirebaseService,
		private storage: StorageService,
		private device: Device,
		private platform: Platform,
		private navCtrl: NavController,
		private splashScreen: SplashScreen,
		private fcm: FCM,
		public toastCtrl: ToastController,
		private network: Network,
		private screenOrient: ScreenOrientation,
		public menuCtrl: MenuController,
		private router: Router,
		public popoverCtrl: PopoverController,
		private loadingController: LoadingController
	) {}

	os: string;
	successToast: HTMLIonToastElement;
	errorToast: HTMLIonToastElement;
	noConnectionToast: HTMLIonToastElement;
	getData = false;
	hiddenMenu = false;
	hiddenHome = true;
	prevLocation: string;
	actualLocation: string;
	appLoading = true;
	darkmode = false;
	loader: HTMLIonLoadingElement;

	menuDoc: DocumentData[] = [];

	env: any = (env as any).default;

	async initializeApp() {
		/* GET PLATFORM INFO */
		this.g.appPrefs.os = this.device.platform;
		this.os = this.device.platform;
		if (this.os === "iOS" || this.os === "Android") {
			this.g.appPrefs.bMobile = true;
		} else {
			this.g.appPrefs.browser = this.device.model;
			if (this.platform.width() > 1024) {
				console.log("W:", this.platform.width(), "H:", this.platform.height());
				this.g.appPrefs.bMobile = false;
			} else {
				this.g.appPrefs.bMobile = true;
			}
		}
		console.log("BMOBILE:", this.g.appPrefs.bMobile);
		console.log("OS:", this.g.appPrefs.os);
		console.log("BROWSER:", this.g.appPrefs.browser);
		console.log("WEBMODE:", this.env.webMode); // <----------- ################################# SETUP WEB OR APP

		/* LISTEN RESIZE CHANGES */
		this.platform.resize.subscribe(async () => {
			if (this.platform.width() > 1024) {
				this.g.appPrefs.bMobile = false;
				this.g.appPrefs.browser = this.device.model;
			} else {
				this.g.appPrefs.bMobile = true;
			}
		});

		// ####################### NOT WEBMODE
		if (!this.env.webMode) {
			this.g.appPrefs.webMode = false;
			/* DISABLE ANDROID HARDWARE BACKBUTTON */
			this.platform.backButton.subscribeWithPriority(9999, () => {
				document.addEventListener(
					"backbutton",
					(event) => {
						event.preventDefault();
						event.stopPropagation();
					},
					false
				);
			});
			/* SET SCREEN ORIENTATION */
			//this.screenOrient.lock(this.screenOrient.ORIENTATIONS.PORTRAIT);
			this.screenOrient.unlock();

			/* INIT CONNECTION */
			await this.initConnection();

			this.fireService.getCacheData().subscribe({
				next: () => {
					console.log("GET ALL DATA FROM CACHE SUCCESSFULLY");
					// this.successToast.present();
					this.g.appPrefs.catalogUnavailable = false;
					this.initSettings();
					this.navCtrl.navigateRoot("/welcome");
					console.log("HIDE SPLASH");
					this.splashScreen.hide();
					this.getData = true;
					this.appLoading = false;
					this.hiddenHome = true;
					this.initMenu();

					if (this.loader) {
						this.loader.dismiss();
					}
				},
				error: (err) => {
					// this.errorToast.present();
					console.log("ERROR", err);
					this.initSettings();
					this.g.appPrefs.catalogUnavailable = true;
					this.hiddenMenu = true;
					this.hiddenHome = true;
					this.navCtrl.navigateRoot("/sync");
					this.splashScreen.hide();
					this.appLoading = false;
				},
			});

			/* TYPESENSE DATA */
			this.fireService.getTypesense();

			/* CONNECT TO FCM */

			if (this.os === "iOS") {
				const wasPermissionGiven: boolean = await this.fcm.requestPushPermissionIOS({
					ios9Support: {
						timeout: 10,
						interval: 0.3,
					},
				});
			}

			this.fcm.onTokenRefresh().subscribe((token) => {
				console.log("FCM TOKEN:", token);
			});

			this.fcm.getToken().then((token) => {
				console.log("FCM TOKEN:", token);
			});
			this.fcm.onNotification().subscribe((data) => {
				if (data.wasTapped) {
					// Notification was received on device tray and tapped by the user.
					if (data.action) {
						//this.navCtrl.navigateRoot([data.action]);
					}
				} else {
					// Notification was received in foreground. Maybe the user needs to be notified.
				}
			});

			this.fcm.subscribeToTopic("notifications");
		} else {
			// ####################### WEBMODE
			this.g.appPrefs.webMode = true;
			this.fireService.getWebData().subscribe({
				next: async () => {
					console.log("DOWNLOAD ALL DATA SUCCESFULLY");
					this.g.appPrefs.catalogUnavailable = false;
					this.initSettings();
					this.initMenu();
					// this.navCtrl.navigateRoot("/welcome");
					this.appLoading = false;
					this.getData = true;
					this.hiddenHome = this.router.url === "/welcome" ? true : false;
				},
				error: async (err) => {
					this.fireService.disableNetwork();
					this.appLoading = false;
					console.error(err);
				},
			});
		}
		// SAVE TYPESENSE COLLECTION
		this.fireService.getTypesense();
		// INIT PREFS
		this.storage.initPrefs();
		this.storage.initRequested();
	}

	private async initConnection() {
		const status = this.network.type;
		if (status === "none") {
			console.log("INIT: CONNECTION NONE");
			this.g.appPrefs.bOffline = true;
			this.noConnectionAlert();
		} else {
			console.log("INIT: CONNECTION " + status);
			this.g.appPrefs.bOffline = false;
		}
		this.network.onChange().subscribe(() => {
			console.log("Change connection", this.network.type);
			this.listenConnectionChanges();
		});
	}

	/* LISTEN CONNECTION CHANGES */
	private listenConnectionChanges() {
		if (this.network.type === "none") {
			this.noConnectionAlert();
			this.g.appPrefs.bOffline = true;
		} else {
			this.g.appPrefs.bOffline = false;
		}
	}

	private async noConnectionAlert() {
		if (this.noConnectionToast) {
			this.noConnectionToast.dismiss();
		}
		this.noConnectionToast = await this.toastCtrl.create({
			message: "Connessione assente",
			duration: 2000,
			color: "warning",
			position: "bottom",
		});
		this.noConnectionToast.present();
	}

	/* INIT SETTINGS FROM LANGUAGE */
	initSettings() {
		this.g.localPrefs = this.storage.getLocalPrefs();
		//console.log('PREFS', this.storage.getLocalPrefs());
		// #################### APPLICO DEFAULT
		this.g.localPrefs.language = "it";

		// console.log('CLIENT LANG:', navigator.language);

		/*
    if (typeof this.g.localPrefs.language === "undefined") {
      let navLang = navigator.language || navigator.userLanguage;
      let clientLang = navLang.split("-")[0];
      // CHECK IF CLIENT LANGUAGE IS AVAILABLE. OTHERWISE SET "IT" AS DEFAULT LANGUAGE
      if (this.g.catalogLanguages.includes(clientLang)) {
        this.g.localPrefs.language = clientLang;
      } else {
        this.g.localPrefs.language = this.env.language;
      }
    }
    */

		this.darkmode = this.g.localPrefs.darkMode;
		if (typeof this.darkmode === "undefined") {
			this.darkmode = this.env.darkMode;
			this.g.localPrefs.darkMode = this.env.darkMode;
		}

		if (typeof this.g.localPrefs.catNavStyle === "undefined") {
			this.g.localPrefs.catNavStyle = this.env.catNavStyle;
		}
		if (typeof this.g.localPrefs.famNavStyle === "undefined") {
			this.g.localPrefs.famNavStyle = this.env.famNavStyle;
		}
		if (typeof this.g.localPrefs.prodNavStyle === "undefined") {
			this.g.localPrefs.prodNavStyle = this.env.prodNavStyle;
		}
		if (typeof this.g.localPrefs.navStyle === "undefined") {
			this.g.localPrefs.navStyle = this.env.navStyle;
		}

		if (!this.g.appPrefs.webMode) {
			this.g.appPrefs.firstTime = this.g.localPrefs.latestSync === undefined ? true : false;
			this.g.appPrefs.firstCount = 0;
			this.g.localPrefs.latestSync;
			this.g.appPrefs.navStyle = this.env.navStyle;
		} else {
			this.g.appPrefs.firstTime = false;
			this.g.appPrefs.firstCount = 0;
			this.g.localPrefs.latestSync;
			this.g.appPrefs.navStyle = this.env.navStyle;
		}

		this.storage.setLocalPrefs(this.g.localPrefs);
	}

	initMenu() {
		this.menuDoc = this.fireService.getSettingsMenuData("gestione menu");
	}

	async presentLoading() {
		this.loader = await this.loadingController.create({
			message: "Attendi...",
			duration: 5000,
		});
		this.loader.present();
	}
}
