import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { CatPageRoutingModule } from './cat-routing.module';

import { CatPage } from './cat.page';
import { CatSidePageModule } from './catside/catside.module';
import { CatMainPageModule } from './catmain/catmain.module';
import { KeyboardPageModule } from '../keyboard/keyboard.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    CatPageRoutingModule,
    CatSidePageModule,
    CatMainPageModule,
    KeyboardPageModule
  ],
  declarations: [CatPage]
})
export class CatPageModule {}
