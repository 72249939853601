import { Component, OnInit } from '@angular/core';
import { PopoverController, NavController } from '@ionic/angular';
import { GService } from 'src/app/services/g.service';
import { Router } from '@angular/router';
import { SearchService } from 'src/app/services/search.service';
@Component({
  selector: 'app-prefpopover',
  templateUrl: './prefpopover.page.html',
  styleUrls: ['./prefpopover.page.scss'],
})
export class PrefpopoverPage implements OnInit {

  constructor(private popoverCtrl: PopoverController, private g: GService, private router: Router, private search: SearchService, private navCtrl: NavController) { }

  ngOnInit() {
  }

  goToInfo() {
    this.search.selectedItem = '';
    this.g.selectedProd = '';
    this.search.cancelFilter();
    if (this.g.appPrefs.bMobile === false) {
      this.navCtrl.navigateForward(['/info']);
    } else {
      this.navCtrl.navigateForward(['/info']);
    }
    this.dismiss();
  }

  dismiss() {
    this.popoverCtrl.dismiss();
  }
}
