import { Component, OnInit, Input } from '@angular/core';
import { FirebaseService } from 'src/app/services/firebase.service';
import { ModalController, Platform } from '@ionic/angular';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';

@Component({
  selector: 'app-videomodal',
  templateUrl: './videomodal.page.html',
  styleUrls: ['./videomodal.page.scss'],
})
export class VideomodalPage implements OnInit {

  @Input() videoID: string;

  video: string;
  videoCatch = false;
  videoError = false;
  constructor(private fire: FirebaseService, private modalCtrl: ModalController, private screenOrient: ScreenOrientation,
              private platform: Platform) { }

  ngOnInit() {
    this.platform.ready().then(async () => {
      // ######### DISABLE ANDROID HARDWARE BACKBUTTON
      this.platform.backButton.subscribeWithPriority(9999, () => {
        document.addEventListener('backbutton', (event) => {
          event.preventDefault();
          event.stopPropagation();
          console.log('BACK');
        }, false);
      });
      this.screenOrient.unlock();
      this.fire.readVideo(this.videoID).then((resVideo: string) => {
        this.video = resVideo;
        this.videoCatch = true;
      }, (err) => {
        this.videoError = true;
        console.error('MAIN GET VIDEO ERROR: ', err);
      });
    });
  }

  dismiss() {
    this.screenOrient.lock(this.screenOrient.ORIENTATIONS.PORTRAIT);
    this.modalCtrl.dismiss();
  }

}
