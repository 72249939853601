import { AfterViewInit, Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { InAppBrowser, InAppBrowserObject } from '@ionic-native/in-app-browser/ngx';
import { NavController } from '@ionic/angular';
import { GService } from 'src/app/services/g.service';
declare let window: any;

@Component({
  selector: 'app-web',
  templateUrl: './web.page.html',
  styleUrls: ['./web.page.scss'],
})
export class WebPage implements OnInit, AfterViewInit {
  pageUrl: SafeResourceUrl;
  correct = false;

  iframe: HTMLIFrameElement;
  constructor(private route: ActivatedRoute, private navCtrl: NavController, private sanitizer: DomSanitizer,
    private iab: InAppBrowser, private g: GService) { }

  ngOnInit() {
    this.route.params.subscribe((param) => {
      this.pageUrl = this.sanitizer.bypassSecurityTrustResourceUrl(param.page);
    });

    window.onmessage = async (event) => {
      let url: string = event.data;
      const type: string = url.substr(0, url.indexOf(':'));
      let systembrowser: InAppBrowserObject;
      switch (type) {
        case 'news':
          url = url.replace('news:', '');
          systembrowser = this.iab.create(url, '_system');
          break;

        case 'admin':
          url = url.replace('admin:', '');
          const downloadUrl = 'https://cm.ocacloud.it/php/download.php?file=' + url;
          systembrowser = this.iab.create(downloadUrl, '_self');
          break;
      }
    };
  }

  ngAfterViewInit() {
    this.iframe = document.getElementById('uiIframe') as HTMLIFrameElement;
    this.iframe.onload = (ev: Event) => {
      console.log('onload iframe');
      this.correct = true;

    };
    this.iframe.onerror = (ev: Event) => {
      console.log('onerror iframe');
      this.correct = false;
    };
  }

  back() {
    this.correct = false;
    // this.navCtrl.navigateBack([this.g.prevLocation]);
    this.navCtrl.back();
  }
}
