import { Component, OnInit, ViewChild } from '@angular/core';
import { FirebaseService } from 'src/app/services/firebase.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NavController, ModalController, PopoverController } from '@ionic/angular';
import { GService } from 'src/app/services/g.service';
import { DocumentData, DocumentReference } from 'angularfire2/firestore';
import { SearchService } from 'src/app/services/search.service';
import { VideomodalPage } from 'src/app/utility/videomodal/videomodal.page';
import { File } from '@ionic-native/file/ngx';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { StorageService } from 'src/app/services/storage.service';
import { PrefpopoverPage } from 'src/app/utility/prefpopover/prefpopover.page';
import { YoutubemodalPage } from 'src/app/utility/youtubemodal/youtubemodal.page';
declare let window: any;

@Component({
  selector: 'app-main',
  templateUrl: './prodmain.page.html',
  styleUrls: ['./prodmain.page.scss'],
})
export class ProdMainPage implements OnInit {
  @ViewChild('content', { static: false }) content: any;
  @ViewChild('title', { static: false }) title: any;
  hasScrollbar: Boolean = false;

  prodId: string;
  prodDoc: DocumentData;
  prodUsage: any[] = [];
  localsDocs: DocumentData;
  images: string[] = [];
  imagesTags: string[] = [];
  document: string;
  selectedGrammage = '';
  selectedFormat = '';
  selectedConf = '';
  grammages: { gram: string, formats: { format: string, confs: { confection: string, palets: string[] }[] }[] }[] = [];
  formats: { format: string, confs: { confection: string, palets: string[] }[] }[] = [];
  confections: { confection: string, palets: string[] }[] = [];
  allFormats: { format: string, available: boolean }[] = [];
  allConfs: { conf: string, available: boolean }[] = [];
  allPalets: { palet: string, available: boolean }[] = [];

  constructor(private fire: FirebaseService, private route: ActivatedRoute, private navCtrl: NavController, private router: Router,
    private g: GService, private search: SearchService, public modalController: ModalController,
    private file: File, private opener: FileOpener, private storage: StorageService,
    public popoverCtrl: PopoverController) { }

  ngOnInit() {
    this.route.params.subscribe((param) => {
      this.prodId = param.prod;
      this.initProd();
      this.initLocals();
    });
  }

  ionViewDidEnter() {
    this.checkForScrollbar();
  }

  async initProd() {
    this.prodDoc = this.fire.getProductDataByCode(this.prodId);
    this.imagesTags = this.storage.getImageTags();
    if (this.imagesTags && this.imagesTags.length > 0) {
      this.fire.readImageArrayWithTags(this.prodDoc.slides).then((resImgs: string[]) => {
        this.images = resImgs;
      }, (err) => {
      });
    } else {
      this.fire.readImageArray(this.prodDoc.slides).then((resImgs: string[]) => {
        this.images = resImgs;
      }, (err) => {
      });
    }
    this.fire.readImage(this.prodDoc.immagine.id).then((resImg: string) => {
      if (!this.images.includes(resImg))
        this.images.push(resImg);
    }, (err) => {
    });
    this.createGrammageArray();
    this.initUsage();
  }

  initLocals() {
    this.fire.getLocalsData();
  }

  back() {
    let layout = this.g.localPrefs.navStyle;
    if (!this.g.appPrefs.bMobile && layout === 'list') {
      this.g.appPrefs.navStyle = this.g.localPrefs.navStyle;
    }
    this.search.selectedItem = '';
    this.search.searchTerm = '';
    this.g.selectedProd = '';
    this.grammages = [];
    this.search.cancelFilter();
    this.navCtrl.back();
  }

  async openVideo() {
    const modal = await this.modalController.create({
      component: VideomodalPage,
      cssClass: "uiDialog",
      componentProps: {
        videoID: this.prodDoc.video[this.g.localPrefs.language].id,
      },
    });
    return await modal.present();
  }

  async openYTVideo() {
    const modal = await this.modalController.create({
      component: YoutubemodalPage,
      cssClass: "uiDialog",
      componentProps: {
        videoURL: this.prodDoc.youtube[this.g.localPrefs.language],
      },
    });
    return await modal.present();
  }

  async openDoc() {
    if (!this.g.appPrefs.bMobile) {
      if (!this.g.appPrefs.webMode) {
        const name = this.fire.getMediaName(this.prodDoc.pdf[this.g.localPrefs.language].id);
        this.fire.readDocument(this.prodDoc.pdf[this.g.localPrefs.language].id).then(
          async (resDoc: any) => {
            const fileBlob: Blob = await this.fire.convertBase64ToBlob(resDoc, "data:application/pdf;base64");
            const fileURL = window.URL.createObjectURL(fileBlob);
            const a = document.createElement("a");
            document.body.appendChild(a);
            a.href = fileURL;
            a.download = name;
            a.target = "_blank";
            a.click();
          },
          (err) => {
            console.log('MAIN: DOC DOES NOT EXISTS');
          }
        );
      } else {
        this.fire.readDocument(this.prodDoc.pdf[this.g.localPrefs.language].id).then(
          async (resDoc: any) => {
            console.log(resDoc);
            window.open(resDoc, '_blank');
          },
          (err) => {
            console.log('MAIN: DOC DOES NOT EXISTS');
          }
        );
      }
    } else {
      if (!this.g.appPrefs.webMode) {
        const name = this.fire.getMediaName(this.prodDoc.pdf[this.g.localPrefs.language].id);
        let directory;
        if (this.g.appPrefs.os === 'Android') { // ############################## ANDROID GET MEDIA DIRECTORY
          directory = this.file.externalApplicationStorageDirectory;
        } else if (this.g.appPrefs.os === 'iOS') { // ############################## IOS GET MEDIA DIRECTORY
          directory = this.file.documentsDirectory;
        }
        this.opener.open(directory + '/documents/' + name, 'application/pdf')
          .catch((err) => {
            console.log('Error opening pdf file', err);
          });
      } else {
        this.fire.readDocument(this.prodDoc.pdf[this.g.localPrefs.language].id).then(
          async (resDoc: any) => {
            console.log(resDoc);
            window.open(resDoc, '_blank');
          },
          (err) => {
            console.log('MAIN: DOC DOES NOT EXISTS');
          }
        );
      }
    }
  }

  async changePreference(ev) {
    const isPrefWithFields = this.storage.isPrefWithFields(this.prodId, this.selectedGrammage, this.selectedFormat);
    if (isPrefWithFields === false) {
      this.storage.modifyPrefs(this.prodId, this.selectedGrammage, this.selectedFormat);
    } else {
      this.storage.removePrefByFields(this.prodId, this.selectedGrammage, this.selectedFormat);
    }
  }

  async requestInfo(ev) {
    const isPrefWithFields = this.storage.isRequestedWithFields(this.prodId, this.selectedGrammage, this.selectedFormat);
    if (isPrefWithFields === false) {
      this.storage.modifyRequested(this.prodId, this.selectedGrammage, this.selectedFormat);
      const popover = await this.popoverCtrl.create({
        component: PrefpopoverPage,
        cssClass: 'uiPrefPopover',
        event: ev,
        translucent: false
      });
      await popover.present();
    } else {
      this.storage.removeRequestedByFields(this.prodId, this.selectedGrammage, this.selectedFormat);
    }
  }

  initUsage() {
    this.prodUsage = [];
    this.prodDoc.categoria.forEach(category => {
      console.log ('CATEGORY USAGE', category.doc_id.id);
      if (category.doc_id.id === 'clas2')
        this.prodUsage.push(this.fire.getCategoryFamByCode(category.doc_id.id, category.cat));
    });
  }

  createGrammageArray() {
    this.grammages = [];
    const grammature = JSON.stringify(this.prodDoc.grammature);
    const jsonObj = JSON.parse(grammature);
    const gramKeys = Object.keys(jsonObj);
    gramKeys.sort(function (a, b) {
      return (a < b) ? -1 : (a > b) ? 1 : 0;
    });
    for (const gramkey of gramKeys) {
      const form: { format: string, confs: any[] }[] = [];
      let confections: string[] = [];
      const formatKeys = Object.keys(jsonObj[gramkey]);
      formatKeys.sort(function (a, b) {
        return (a < b) ? -1 : (a > b) ? 1 : 0;
      });
      for (const formKey of formatKeys) {
        const conf: { confection: string, palets: string[] }[] = [];
        let palettizations: string[] = [];
        const confsKeys = Object.keys(jsonObj[gramkey][formKey]);
        confsKeys.sort(function (a, b) {
          return (a < b) ? -1 : (a > b) ? 1 : 0;
        });
        for (const confKey of confsKeys) {
          Object.keys(jsonObj).forEach((value) => {
            palettizations = Object.values(jsonObj[gramkey][formKey][confKey]);
          });
          for (const pal of palettizations) {
            const pIndex = this.allPalets.findIndex(p => p.palet === pal);
            if (pIndex < 0) {
              const palet = { palet: pal, available: false };
              this.allPalets.push(palet);
            }
          }
          /*
          this.allPalets.sort(function (a: any, b: any) {
            a = parseInt(a.conf.split(' ')[0]);
            b = parseInt(b.conf.split(' ')[0]);
            return (a < b) ? -1 : (a > b) ? 1 : 0;
          });
          */
          conf.push({ confection: confKey, palets: palettizations });
          const cIndex = this.allConfs.findIndex(c => c.conf === confKey);
          if (cIndex < 0) {
            const confection = { conf: confKey, available: false };
            this.allConfs.push(confection);
          }
        }
        form.push({ format: formKey, confs: conf });
        const fIndex = this.allFormats.findIndex(f => f.format === formKey);
        if (fIndex < 0) {
          const format = { format: formKey, available: false };
          this.allFormats.push(format);
        }
      }
      this.grammages.push({ gram: gramkey, formats: form });
      console.log(this.grammages);
    }

  }

  selectGrammage(gram) {
    this.selectedGrammage = gram;
    this.formats = [];
    this.selectedFormat = '';
    this.selectedConf = '';
    this.allPalets.forEach(p => {
      p.available = false;
    });
    
    this.allFormats.forEach(f => {
      f.available = false;
    });
    this.allConfs.forEach(c => {
      c.available = false;
    });
    this.allPalets.forEach(p => {
      p.available = false;
    });
    let gramIndex;
    for (let g = 0; g < this.grammages.length; g++) {
      if (this.grammages[g].gram === gram) {
        gramIndex = g;
      }
    }
    this.formats = this.grammages[gramIndex].formats;
    this.formats.forEach(f => {
      const checkFormat = { format: f.format, available: false };
      const fIndex = this.allFormats.findIndex(form => form.format === checkFormat.format);
      if (fIndex > -1) {
        this.allFormats[fIndex].available = true;
      }
    });
  }

  selectFormat(form) {
    /*
    let confs = [];
    this.selectedFormat = form;
    this.allConfs.forEach(c => {
      c.available = false;
    });
    this.formats.forEach(f => {
      if (f.format === form) {
        confs = f.confs;
      }
    });
    confs.forEach(c => {
      const checkConf = { conf: c, available: false };
      const cIndex = this.allConfs.findIndex(conf => conf.conf === checkConf.conf);
      if (cIndex > -1) {
        this.allConfs[cIndex].available = true;
      }
    });
    */
    this.allConfs.forEach(c => {
      c.available = false;
    });
    this.allPalets.forEach(p => {
      p.available = false;
    });
    this.selectedFormat = form;
    this.selectedConf = '';
    this.confections = [];
    let formIndex;
    for (let f = 0; f < this.formats.length; f++) {
      if (this.formats[f].format === form) {
        formIndex = f;
      }
    }
    this.confections = this.formats[formIndex].confs;
    this.confections.forEach(c => {
      const checkConf = { conf: c.confection, available: false };
      const cIndex = this.allConfs.findIndex(conf => conf.conf === checkConf.conf);
      if (cIndex > -1) {
        this.allConfs[cIndex].available = true;
      }
    });
  }

  selectConf(conf) {
    this.allPalets.forEach(p => {
      p.available = false;
    });
    let palets = [];
    this.selectedConf = conf;
    this.allPalets.forEach(p => {
      p.available = false;
    });
    this.confections.forEach(c => {

      if (c.confection === conf) {
        palets = c.palets;
      } 
    });
    palets.forEach(p => {
      const checkPal = { pal: p, available: false };
      const pIndex = this.allPalets.findIndex(pal => pal.palet === checkPal.pal);
      if (pIndex > -1) {
        this.allPalets[pIndex].available = true;
      }
    });
    /*
    let palets = [];
    this.selectedConf = conf;
    this.allConfs.forEach(c => {
      c.available = false;
    });
    this.confections.forEach(c => {
      if (c.confection === conf) {
        palets = c.palets;
      }
    });
    palets.forEach(p => {
      const checkPal = { palet: p, available: false };
      const pIndex = this.allPalets.findIndex(pal => pal.palet === checkPal.palet);
      if (pIndex > -1) {
        this.allPalets[pIndex].available = true;
      }
    });

    */
  }

  checkForScrollbar() {
    this.content.getScrollElement().then((scrollElement) => {
      this.hasScrollbar = (scrollElement.scrollHeight > scrollElement.clientHeight);
    });
  }

  scrollToBottom() {
    this.content.scrollToPoint(0, this.title.nativeElement.offsetTop, 1000);
  }
}
