import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FirebaseService } from 'src/app/services/firebase.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NavController, ModalController } from '@ionic/angular';
import { GService } from 'src/app/services/g.service';
import { SearchService } from 'src/app/services/search.service';
import { VideomodalPage } from '../../../utility/videomodal/videomodal.page';
import { StorageService } from 'src/app/services/storage.service';
import Keyboard from 'simple-keyboard';

@Component({
  selector: 'app-main',
  templateUrl: './catmain.page.html',
  styleUrls: ['./catmain.page.scss'],
})

export class CatMainPage implements OnInit {

  
  hasScrollbar: Boolean = false;

  classId: string;
  catId: string;
  famId: string;
  level: string;
  images: string[] = [];
  video: string;
  displayedRes: any;
  enableForward = false;
  availableForward = false;
  classKeyboard: Keyboard;
  catKeyboard: Keyboard;

  slideOpts = {
    loop: true,
    allowTouchMove: true,
    grabCursor: true,
    spaceBetween: 0,
    zoom: true,
  };

  @ViewChild('catContent', { static: false }) catContent: any;
  @ViewChild('famContent', { static: false }) famContent: any;
  @ViewChild('catTitle', { static: false }) catTitle: any;
  @ViewChild('famTitle', { static: false }) famTitle: any;

  constructor(private fire: FirebaseService, private route: ActivatedRoute, private navCtrl: NavController, private router: Router,
    private g: GService, private search: SearchService, public modalController: ModalController,
    private storage: StorageService) { }

  ngOnInit() {
    console.log('init');
    this.route.params.subscribe((param) => {
      this.classId = param.class;
      this.catId = param.cat;
      this.famId = param.fam;
      console.log(this.classId, ' - ', this.catId, ' - ', this.famId);
      if (this.catId === '-') {
        this.level = 'class';
        this.initClass();
      } else if (this.famId === '-') {
        this.level = 'cat';
        this.initCat();
      } else {
        this.level = 'fam';
        this.initFam();
      }
    });
    
  }

  ionViewDidEnter() {
    this.checkForScrollbar();
  }

  async initClass() {
    const classDoc = this.fire.getCategoryData(this.classId);
    this.displayedRes = classDoc;
    // togliere 'id'? doc.immagine -> document reference
    this.fire.readImageArray(classDoc.slides).then((resImgs: string[]) => {
      this.images = resImgs;
    }, (err) => {
    });
    this.fire.readImage(this.displayedRes.immagine.id).then((resImg: string) => {
      this.images.push(resImg);
    }, (err) => {
    });
  }

  async initCat() {
    const classDoc = this.fire.getCategoryData(this.classId);
    const catFilter = classDoc.categorie.filter((cat) => {
      return cat.id === this.catId;
    });
    this.displayedRes = catFilter[0];
    this.fire.readImageArray(this.displayedRes.slides).then((resImgs: string[]) => {
      this.images = resImgs;
    }, (err) => {
    });

    this.fire.readImage(this.displayedRes.immagine.id).then((resImg: string) => {
      this.images.push(resImg);
    }, (err) => {
    });
    this.enableForward = true;
    // controllo se famiglia disponibile
    const famFilter = this.displayedRes.famiglie;
    if (famFilter.length > 0) {
      this.availableForward = true;
    }
  }

  async initFam() {
    console.log('INIT FAM', this.classId);
    const classDoc = this.fire.getCategoryData(this.classId);
    const catFilter = classDoc.categorie.filter((cat) => {
      return cat.id === this.catId;
    });
    const selectedCat = catFilter[0];
    const famFilter = selectedCat.famiglie.filter((fam) => {
      return fam.id === this.famId;
    });
    this.displayedRes = famFilter[0];
    this.fire.readImageArray(this.displayedRes.slides).then((resImgs: string[]) => {
      this.images = resImgs;
    }, (err) => {
    });

    this.fire.readImage(this.displayedRes.immagine.id).then((resImg: string) => {
      this.images.push(resImg);
    }, (err) => {
    });
    this.enableForward = true;
    // controllo se prodotti disponibili
    const products = this.fire.getProducts(this.classId, this.catId, this.famId);
    if (products.length > 0) {
      this.availableForward = true;
    }
  }

  back() {
    this.search.selectedItem = '';
    this.search.searchCategory = '';
    this.search.searchFamily = '';
    this.hasScrollbar = false;
    this.search.cancelFilter();
    this.navCtrl.back();
  }

  forward() {
    this.hasScrollbar = false;
    if (!this.g.appPrefs.bMobile) {
      this.search.searchCategory = '';
      this.search.searchFamily = '';
      this.enableForward = false;
      if (this.level === 'fam') {
        this.navCtrl.navigateForward(['/prod', {
          outlets: {
            SideRouter: 'side/' + this.classId + '/' + this.catId + '/' + this.famId,
            MainRouter: 'products'
          }
        }]);
      } else {
        this.displayedRes.tag ? this.storage.setImageTags(this.displayedRes.tag) : '';
        this.navCtrl.navigateForward(['/cat', {
          outlets: {
            SideRouter: 'side/' + this.classId + '/' + this.catId,
            // MainRouter: 'main/' + this.classId + '/' + this.catId + '/' + ''
            MainRouter: 'families'
          }
        }]);
      }
    } else {
      this.enableForward = false;
      this.search.searchCategory = '';
      this.search.searchFamily = '';
      if (this.storage.getImageTags()) {
        console.log('true');
        this.displayedRes.tag ? this.storage.addImageTags(this.displayedRes.tag) : '';
      } else {
        console.log('false');
        this.displayedRes.tag ? this.storage.setImageTags(this.displayedRes.tag) : this.storage.setImageTags([]);
      }
      if (this.level === 'fam') {
        this.navCtrl.navigateForward(['/prod', {
          outlets: {
            SideRouter: 'side/' + this.classId + '/' + this.catId + '/' + this.famId,
            MainRouter: 'products'
          }
        }]);
      } else {
        this.navCtrl.navigateForward(['/cat', { outlets: { SideRouter: 'side/' + this.classId + '/' + this.catId } }]);
      }
    }
  }

  async openVideo() {
    const modal = await this.modalController.create({
      component: VideomodalPage,
      cssClass: "uiDialog",
      componentProps: {
        videoID: this.displayedRes.video[this.g.localPrefs.language].id,
      },
    });
    return await modal.present();
  }

  onKeyPress = (button: string) => {
    if (button === '{shift}' || button === '{lock}') {
      this.handleShift();
    } else if (button === '{enter}') {
      /* handle enter button*/
    }
  }

  onInputChange = (event: any) => {
    this.catKeyboard.setInput(event.target.value);
  }

  handleShift = () => {
    const currentLayout = this.catKeyboard.options.layoutName;
    const shiftToggle = currentLayout === 'default' ? 'shift' : 'default';
    this.catKeyboard.setOptions({
      layoutName: shiftToggle
    });
  }

  onChange = (input: string) => {
    this.search.searchFamily = input;
  }

  onKeyPressClass = (button: string) => {
    if (button === '{shift}' || button === '{lock}') {
      this.handleShift();
    } else if (button === '{enter}') {
      /* handle enter button*/
    }
  }

  onInputChangeClass = (event: any) => {
    this.classKeyboard.setInput(event.target.value);
  }

  handleShiftClass = () => {
    const currentLayout = this.classKeyboard.options.layoutName;
    const shiftToggle = currentLayout === 'default' ? 'shift' : 'default';
    this.classKeyboard.setOptions({
      layoutName: shiftToggle
    });
  }

  onChangeClass = (input: string) => {
    this.search.searchCategory = input;
  }

  checkForScrollbar() {
    if (this.level === 'cat') {
      this.catContent.getScrollElement().then((scrollElement) => {
        this.hasScrollbar = (scrollElement.scrollHeight > scrollElement.clientHeight);
      });
    } else if (this.level === 'fam') {
      this.famContent.getScrollElement().then((scrollElement) => {
        this.hasScrollbar = (scrollElement.scrollHeight > scrollElement.clientHeight);
      });
    }
  }

  scrollToBottom() {
    if (this.level === 'cat') {
      this.catContent.scrollToPoint(0, this.catTitle.nativeElement.offsetTop, 1000);
    } else if (this.level === 'fam') {
      this.famContent.scrollToPoint(0, this.famTitle.nativeElement.offsetTop, 1000);
    }
  }
}
