import { Component, OnInit, ViewChild } from '@angular/core';
import { AnimationController, createAnimation, NavController } from '@ionic/angular';
import { Router } from '@angular/router';
import { FirebaseService } from 'src/app/services/firebase.service';
import { DocumentData } from 'angularfire2/firestore';
import { GService } from 'src/app/services/g.service';

@Component({
  selector: 'app-index',
  templateUrl: './index.page.html',
  styleUrls: ['./index.page.scss'],
})
export class IndexPage implements OnInit {
  docs: DocumentData[];
  docTitles: { 'titolo': string, 'id': number, 'icona': string }[] = [];
  sliders: any[] = [];
  contentReady: boolean = false;
  hasScrollbar: Boolean = false;

  @ViewChild('slides', { static: false }) slides: any;
  @ViewChild('iContent', { static: false }) iContent: any;
  constructor(private animCtrl: AnimationController, private navCtrl: NavController, private router: Router, private fire: FirebaseService, private g: GService) { }

  ngOnInit() {
    this.inizialiateDocs();
    // this.initSlide();
    this.initPopup();
  }

  async ionViewDidEnter() {
    if (!this.contentReady) {
      await this.initSlide();
      const animation = this.animCtrl.create().addElement(this.slides.nativeElement).duration(500).fromTo('opacity', '0', '1');
      animation.play();
    }
  }

  ionViewWillEnter() {
    this.checkForScrollbar();
  }

  inizialiateDocs() {
    this.docs = this.fire.getCategoriesData();
    const catsDocs = this.fire.getCategoriesData();
    for (const cat of catsDocs) {
      this.docTitles.push({ titolo: cat.data().titolo, id: cat.id, icona: cat.data().icona });
    }
  }

  initSlide() {
    const slidersDocs = this.fire.getSettingsImagesData('gestione immagini');
    this.fire.readImageArray(slidersDocs.cat_slideshow).then((resImgs: string[]) => {
      this.sliders = resImgs;
      this.contentReady = true;
    }, (err) => {
      console.error('MAIN GET IMAGE ERROR: ', err);
    });
  }

  back() {
    this.navCtrl.back();
  }

  goTo(doc: string) {
    if (this.g.appPrefs.bMobile === false) {
      this.navCtrl.navigateForward(['/cat', {
        outlets: {
          SideRouter: 'side/' + doc + '/' + '-',
          MainRouter: 'categories'
        }
      }])
      //MainRouter: 'main/' + doc + '/' + '' + '/' + ''}}]);
    } else {
      this.navCtrl.navigateForward(['/cat', { outlets: { SideRouter: 'side/' + doc + '/' + '-' } }]);
    }
  }

  checkForScrollbar() {
    this.iContent.getScrollElement().then((scrollElement) => {
      this.hasScrollbar = (scrollElement.scrollHeight > scrollElement.clientHeight);
    });
  }

  goToSearch() {
    this.navCtrl.navigateForward('/search/initsearch');
  }

  initPopup() {

  }
}
