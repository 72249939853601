import { Injectable } from "@angular/core";
import { DocumentData } from "angularfire2/firestore";
import { FirebaseService } from "./firebase.service";
import { GService } from "./g.service";

@Injectable({
	providedIn: "root",
})
export class SearchService {
	// select item on cat -> side.page and prod -> side.page components
	selectedItem = "";
	// for search on prod -> side.page and prod -> main.page components
	searchTerm = "";
	searchCategory = "";
	searchFamily = "";
	productsList: DocumentData[] = [];
	displayedDoc: DocumentData[] = [];
	displayedCats: DocumentData[] = [];
	categoriesList: DocumentData[] = [];
	familiesList: DocumentData[] = [];
	displayedFams: DocumentData[] = [];

	constructor(private g: GService, private fire: FirebaseService) { }

	cancelFilter() {
		this.searchTerm = "";
		this.displayedDoc = this.productsList;
	}

	setFilteredItems() {
		this.displayedDoc = this.productsList.filter((doc) => {
			return doc.nome[this.g.localPrefs.language].toLowerCase().includes(this.searchTerm.toLowerCase());
		});
	}

	setProducts(products: DocumentData[]) {
		this.productsList = products;
		this.displayedDoc = products;
	}

	addProduct(prod: DocumentData) {
		this.displayedDoc.push(prod);
	}


	setCategories(categories: DocumentData[]) {
		this.categoriesList = categories;
		this.displayedCats = categories;
	}

	setFilteredCats() {
		this.displayedCats = this.categoriesList.filter((doc) => {
			return doc.nome[this.g.localPrefs.language].toLowerCase().includes(this.searchCategory.toLowerCase());
		});
	}

	cancelCatsFilter() {
		this.searchCategory = "";
		this.displayedCats = this.categoriesList;
	}

	setFamilies(categories: DocumentData[]) {
		this.familiesList = categories;
		this.displayedFams = categories;
	}

	setFilteredFams() {
		this.displayedFams = this.familiesList.filter((doc) => {
			return doc.nome[this.g.localPrefs.language].toLowerCase().includes(this.searchFamily.toLowerCase());
		});
	}

	cancelFamsFilter() {
		this.searchFamily = "";
		this.displayedFams = this.familiesList;
	}

}
