import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { StorageService } from './storage.service';
import { NgZone } from '@angular/core';

export interface AppPrefs {
	bMobile: boolean;
	os: string;
	browser: string;
	catalogUnavailable: boolean;
	bOffline: boolean;
	firstTime: boolean;
	firstCount: number;
	webMode: boolean;
	navStyle: string;
	darkMode: boolean;
}

export interface LocalPrefs {
	latestSync: string;
	language: string;
	navStyle: string;
	catNavStyle: string;
	famNavStyle: string;
	prodNavStyle: string;
	darkMode: boolean;
}

export interface Language {
	tag: string;
}

export interface Settings {
	id: string;
	text: Language;
}

@Injectable({
	providedIn: "root",
})
export class GService {
	/* TEXT UTILITIES */
	public btnSettings: Settings[] = [];
	public texts: { [key: string]: { text: string } } = {};
	public prodFields: { [key: string]: { text: any } } = {};
	public prevLocation: string;
	public hasSync = false;
	public selectedProd = '';

	/* OTHER GLOBAL UTILITIES */
	public appPrefs: AppPrefs = { bMobile: true, bOffline: false } as AppPrefs;
	public localPrefs: LocalPrefs = { language: "it" } as LocalPrefs;
	public catalogLanguages: string[] = ["it", "en"];

	slideOpts = {
		autoplay: {
			disableOnInteraction: false,
			delay: 5000,
			paused: false,
		},
		lazy: false,
		loop: true,
		initialSlide: 0,
		speed: 1000,
		allowTouchMove: false,
		grabCursor: false,
		spaceBetween: 0,
		updateOnImagesReady: true,
		pagination: false,
		zoom: false,
		on: {
			beforeInit() {
				const swiper = this;
				swiper.classNames.push(`${swiper.params.containerModifierClass}fade`);
				const overwriteParams = {
					slidesPerView: 1,
					slidesPerColumn: 1,
					slidesPerGroup: 1,
					watchSlidesProgress: true,
					spaceBetween: 0,
					virtualTranslate: true,
				};
				swiper.params = Object.assign(swiper.params, overwriteParams);
				swiper.params = Object.assign(swiper.originalParams, overwriteParams);
			},
			setTranslate() {
				const swiper = this;
				const { slides } = swiper;
				for (let i = 0; i < slides.length; i += 1) {
					const $slideEl = swiper.slides.eq(i);
					const offset$$1 = $slideEl[0].swiperSlideOffset;
					let tx = -offset$$1;
					if (!swiper.params.virtualTranslate) tx -= swiper.translate;
					let ty = 0;
					if (!swiper.isHorizontal()) {
						ty = tx;
						tx = 0;
					}
					const slideOpacity = swiper.params.fadeEffect.crossFade
						? Math.max(1 - Math.abs($slideEl[0].progress), 0)
						: 1 + Math.min(Math.max($slideEl[0].progress, -1), 0);
					$slideEl
						.css({
							opacity: slideOpacity,
						})
						.transform(`translate3d(${tx}px, ${ty}px, 0px)`);
				}
			},
			setTransition(duration) {
				const swiper = this;
				const { slides, $wrapperEl } = swiper;
				slides.transition(duration);
				if (swiper.params.virtualTranslate && duration !== 0) {
					let eventTriggered = false;
					slides.transitionEnd(() => {
						if (eventTriggered) return;
						if (!swiper || swiper.destroyed) return;
						eventTriggered = true;
						swiper.animating = false;
						const triggerEvents = ['webkitTransitionEnd', 'transitionend'];
						for (let i = 0; i < triggerEvents.length; i += 1) {
							$wrapperEl.trigger(triggerEvents[i]);
						}
					});
				}
			},
		}
	};

	/* HTTP REQUEST FIELDS */
	private header = new HttpHeaders();
	private baseUrl = "https://catalogo.esseoquattro.it/admin/php";
	// DEV MODE:
	// private baseUrl = 'http://localhost:3000/php';

	constructor(private http: HttpClient, private storage: StorageService, private zone: NgZone) {
		/*
		this.events.subscribe('updateScreen', () => {
			this.zone.run(() => {
			  console.log('force update the screen');
			});
		  });
		  */
	}

	sendMail(formData: FormData) {
		this.header.append("Content-Type", "application/x-www-form-urlencoded");
		return this.http.post(this.baseUrl + "/sendmail.php", formData, { headers: this.header })
			.pipe(catchError((err: HttpErrorResponse) => {
				if (err.error instanceof Error) {
					// A client-side or network error occurred
					return throwError(err);
				} else {
					// A backend error occured (err.status = 500)
					const details = { detail: err.error, status: err.status };
					return throwError(details);
				}
			})
			);
	}

	changeProdNavStyle(layout) {
		setTimeout(() => {
			this.appPrefs.navStyle = layout;
			this.localPrefs.prodNavStyle = layout;
			this.localPrefs.navStyle = layout;
			this.selectedProd = '';
			this.storage.setLocalPrefs(this.localPrefs);
		}, 500);
	}

	changeCatStyle(layout) {
		setTimeout(() => {
			this.appPrefs.navStyle = layout;
			this.localPrefs.navStyle = layout;
			this.localPrefs.catNavStyle = layout;
			this.storage.setLocalPrefs(this.localPrefs);
		}, 500);
	}

	changeFamStyle(layout) {
		setTimeout(() => {
			this.appPrefs.navStyle = layout;
			this.localPrefs.navStyle = layout;
			this.localPrefs.famNavStyle = layout;
			this.storage.setLocalPrefs(this.localPrefs);
		}, 500);
	}

	changeDarkMode(dark) {
		this.appPrefs.darkMode = dark;
		this.localPrefs.darkMode = dark;
		this.storage.setLocalPrefs(this.localPrefs);
	}
}
