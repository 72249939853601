import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FirebaseService } from 'src/app/services/firebase.service';
import { ActivatedRoute, Router } from '@angular/router';
import { GService } from 'src/app/services/g.service';
import { DocumentData } from 'angularfire2/firestore';
import { createAnimation, NavController } from '@ionic/angular';
import { SearchService } from 'src/app/services/search.service';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-side',
  templateUrl: './prodside.page.html',
  styleUrls: ['./prodside.page.scss'],
})
export class ProdSidePage implements OnInit {
  classId: string;
  catId: string;
  famId: string;
  prodCode: string;
  products: DocumentData[] = [];
  prodsImg: { [id: string]: { img: string, slides: string[]; available: boolean; } } = {};
  showMobSearch = false;
  internalSlideOpts = {
    loop: false,
    allowTouchMove: true,
    grabCursor: true,
    spaceBetween: 0,
    zoom: false,
  };
  segment: string = 'list';
  contentReady: boolean = false;

  pageTitle: string = '';

  constructor(private fire: FirebaseService, private route: ActivatedRoute, private g: GService, private router: Router,
    private navCtrl: NavController, private search: SearchService, @Inject(DOCUMENT) private document: HTMLDocument) { }

  @ViewChild('searchProdBar', { static: false }) searchProdBar: any;

  ngOnInit() { }
  
  ionViewWillEnter() {
    this.route.params.subscribe((param) => {
      this.classId = param.class;
      this.catId = param.cat;
      this.famId = param.fam;
    });
    this.g.appPrefs.navStyle = this.g.localPrefs.navStyle;
    this.segment = this.g.localPrefs.navStyle;

    this.g.selectedProd = '';
    this.initProducts();
  }


  async ionViewDidEnter() {
    if (!this.contentReady) {
      for (const p of this.products) {
        await this.readProductsImg(p.immagine, p.codice, p);
        this.prodsImg[p.codice].available = true;
        const anim = createAnimation().addElement(document.querySelectorAll('.uiSwiperFadeProd' + p.codice)).duration(500).fromTo('opacity', '0', '1');
        anim.play();
      }
      this.contentReady = true;
    }
  }

  initProducts() {
    this.products = this.fire.getProducts(this.classId, this.catId, this.famId);
    const classDoc = this.fire.getCategoryData(this.classId);
    const catDoc = classDoc.categorie.filter((cat) => {
			return cat.id === this.catId;
		});
    const famDoc= catDoc[0].famiglie.filter((fam) => {
			return fam.id === this.famId;
		});

    this.pageTitle = classDoc.titolo[this.g.localPrefs.language] + ' - ' + catDoc[0].nome[this.g.localPrefs.language] + ' - ' + famDoc[0].nome[this.g.localPrefs.language];

    this.products.sort((a, b) => {
      if (a.priorita > b.priorita) {
        return -1;
      }
      if (a.priorita < b.priorita) {
        return 1;
      }
      return 0;
    });
    this.search.setProducts(this.products);
    /*
    for (const p of this.products) {
      this.readProductsImg(p.immagine, p.codice, p);
    }
    */
  }

  async readProductsImg(prodImg, code, doc) {
    this.prodsImg[code] = { img: '', slides: [], available: false };
    this.prodsImg[code].slides = [];
    await this.fire.readImage(prodImg.id).then((resImg: string) => {
      this.prodsImg[code].img = resImg;
      this.prodsImg[code].slides.push(resImg);
    }, (err) => {

    });

    await this.fire.readImageArray(doc.slides).then((resImgs: string[]) => {
      for (let res of resImgs) {
        this.prodsImg[code].slides.push(res);
      }
    }, (err) => {

    });
  }

  navigateProd(prodCode) {
    if (this.search.selectedItem !== prodCode) {
      this.g.selectedProd = prodCode;
      this.showMobSearch = false;
      // this.search.searchTerm = '';
      this.search.selectedItem = prodCode;
      this.prodCode = prodCode;
      if (this.g.appPrefs.bMobile === false) {
        if (this.g.localPrefs.navStyle === 'list') {
          this.navCtrl.navigateForward(['/prod', {
            outlets: {
              SideRouter: 'side/' + this.classId + '/' + this.catId + '/' + this.famId,
              MainRouter: 'main/' + this.prodCode
            }
          }], { skipLocationChange: true, replaceUrl: true }).then(() => {
            this.search.selectedItem = prodCode;
          });
          ;
        } else {
          this.navCtrl.navigateForward(['/prod', {
            outlets: {
              SideRouter: 'side/' + this.classId + '/' + this.catId + '/' + this.famId,
              MainRouter: 'main/' + this.prodCode
            }
          }]);
        }
      } else {
        this.navCtrl.navigateForward(['/prod', { outlets: { SideRouter: 'main/' + this.prodCode } }]);
      }
    } else {
      if (this.g.appPrefs.bMobile === false) {
        this.search.selectedItem = '';
        this.navCtrl.navigateBack([
          "/prod",
          {
            outlets: {
              SideRouter: "side/" + this.classId + "/" + this.catId + "/" + this.famId,
              MainRouter: "products",
            },
          },
        ]);
      }
    }
  }

  cancelFilter() {
    this.showMobSearch = false;
    this.search.searchTerm = '';
    this.search.cancelFilter();
  }

  back() {
    this.g.selectedProd = '';
    this.showMobSearch = false;
    this.search.selectedItem = '';
    this.search.searchTerm = '';
    this.g.appPrefs.navStyle = this.g.localPrefs.navStyle;
    this.search.cancelFilter();
    this.navCtrl.back();
  }

  focusSearch() {
    this.searchProdBar.setFocus();
  }

  navigateBack() {
    if (this.g.appPrefs.bMobile === false) {
      this.search.selectedItem = '';
      this.navCtrl.navigateBack([
        "/prod",
        {
          outlets: {
            SideRouter: "side/" + this.classId + "/" + this.catId + "/" + this.famId,
            MainRouter: "products",
          },
        },
      ]);
    }
  }
}
