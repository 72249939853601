import { NgModule } from '@angular/core';
import { BrowserModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

// FIRESTORE MODULES
import { AngularFireModule } from 'angularfire2';
import { AngularFirestoreModule } from 'angularfire2/firestore';
import { firebaseConfig } from './firestore.config';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { WelcomePageModule } from './pages/welcome/welcome.module';
import { MainPageModule } from './pages/main/main.module';
import { Network } from '@ionic-native/network/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { WebPageModule } from './pages/web/web.module';
import { SyncPageModule } from './pages/sync/sync.module';
import { IndexPageModule } from './pages/index/index.module';
import { CatPageModule } from './pages/cat/cat.module';
import { Device } from '@ionic-native/device/ngx';
import { File } from '@ionic-native/file/ngx';
import { FCM } from '@ionic-native/fcm/ngx';
import { ProdPageModule } from './pages/prod/prod.module';
import { VideomodalPage } from './utility/videomodal/videomodal.page';
import { PrefpopoverPage } from './utility/prefpopover/prefpopover.page';
import { PrefPageModule } from './pages/pref/pref.module';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { HttpClientModule } from '@angular/common/http';
import { IonicGestureConfig } from './ionicgestureconfig';
import { InfoPageModule } from './pages/info/info.module';
import { FormsModule } from '@angular/forms';
import { LanguagepopoverPage } from './utility/languagepopover/languagepopover.page';
import { InfoprodPageModule } from './pages/info/infoprod/infoprod.module';
import { SearchPageModule } from './pages/search/search.module';
import { YoutubemodalPage } from './utility/youtubemodal/youtubemodal.page';
import { HomepopoverPage } from './utility/homepopover/homepopover.page';

@NgModule({
  declarations: [AppComponent, VideomodalPage, HomepopoverPage, YoutubemodalPage, PrefpopoverPage, LanguagepopoverPage],
  entryComponents: [ VideomodalPage, HomepopoverPage, YoutubemodalPage, PrefpopoverPage, LanguagepopoverPage],
  imports: [
    BrowserModule,
    FormsModule,
    IonicModule.forRoot({
      mode: 'ios'
    }),
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFirestoreModule,
    AppRoutingModule,
    WelcomePageModule,
    MainPageModule,
    WebPageModule,
    SyncPageModule,
    IndexPageModule,
    CatPageModule,
    ProdPageModule,
    PrefPageModule,
    InfoPageModule,
    InfoprodPageModule,
    SearchPageModule,
    HttpClientModule
  ],
  providers: [
    InAppBrowser,
    Network,
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    Device,
    File,
    FCM,
    ScreenOrientation,
    FileOpener,
    { provide: HAMMER_GESTURE_CONFIG, useClass: IonicGestureConfig },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}

