import { Component, OnInit } from '@angular/core';
import { GService } from 'src/app/services/g.service';

@Component({
  selector: 'app-prod',
  templateUrl: './prod.page.html',
  styleUrls: ['./prod.page.scss'],
})
export class ProdPage implements OnInit {

  constructor(private g: GService) { }

  ngOnInit() {
  }

}
